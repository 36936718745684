import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './css/index.css';

import ChangePassword from "./js/change-Password";
import ClientRegistration from "./js/client-Registration";
import CreateClient from "./js/create-Client";
import CreateControlSpentHours from "./js/create-Control-Spent-Hours";
import CreateProject from "./js/create-Project";
import CreateUser from "./js/create-User";
import EditClient from "./js/edit-Client";
import EditControllSpentHours from "./js/edit-Controll-Spent-Hour";
import EditProject from "./js/edit-Project";
import EditUser from "./js/edit-User";
import ControlSpentHours from "./js/entry-control-Spent-Hours";
import Login from "./js/login";
import ProjectRegistration from "./js/project-Registration";
import RegisterHours from "./js/register-Hours";
import UserRegistration from "./js/user-Registration";

import { authentication_controller } from "./Controller/Authentication";

//Apagado
// import EntryControlRegister from "./js/entry-Control-Registation";
// import EntryControlEdit from "./js/edit-Entry-Control";
// import CreateControlHour from "./js/create-Control-Hour-Registration";

const Rota = () => {
    const [authenticationResponse, setAuthenticationResponse] = useState(null);

    useEffect(() => {
        authentication_controller.authenticateRoutes(function (sucess) {
            setAuthenticationResponse(sucess.data.isAdmin);
        })
    }, [])

    if (authenticationResponse == true) {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Login />} />

                    <Route path="/user-registration" element={<UserRegistration />} />
                    <Route path="/user-registration/new" element={<CreateUser />} />
                    <Route path="/user-registration/edit-user:id" element={<EditUser />} />
                    <Route path="/client-registration:id" element={<ClientRegistration />} />
                    <Route path="/client-registration/new" element={<CreateClient />} />
                    <Route path="/client-registration/edit-client:id" element={<EditClient />} />
                    <Route path="/project-registration" element={<ProjectRegistration />} />
                    <Route path="/project-registration/new" element={<CreateProject />} />
                    <Route path="/project-registration/edit-project:id" element={<EditProject />} />

                    {/* Apagado */}
                    {/* <Route path="/entry-control-register" element={<EntryControlRegister />} />
                    <Route path="/entry-control-register/new" element={<CreateControlHour />} />
                    <Route path="/entry-control-register/edit-entry-control:id" element={<EntryControlEdit />} /> */}

                    <Route path="/entry-control-spent-hours" element={<ControlSpentHours />} />
                    <Route path="/entry-control-spent-hours/new" element={<CreateControlSpentHours />} />
                    <Route path="/entry-control-spent-hours/edit-spent-hours:id" element={<EditControllSpentHours />} />

                    <Route path="/change-password" element={<ChangePassword />} />
                    <Route path="/register-hours" element={<RegisterHours />} />


                </Routes>
            </BrowserRouter>
        );
    } else if (authenticationResponse == false) {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/change-password" element={<ChangePassword />} />
                    <Route path="/register-hours" element={<RegisterHours />} />
                </Routes>
            </BrowserRouter>
        );
    } else {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Login />} />
                </Routes>
            </BrowserRouter>
        );
    }
}

export default Rota;