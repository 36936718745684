import { nanoid } from 'nanoid';
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Table } from "reactstrap";
import "../css/userRegistration.css";
import InformationClient from './information-Client';
import NavMenu from "./navMenu";

import { client_controller } from '../Controller/Cliente';

import DeleteModal from './delete-Modal';

export default function ClientResistration() {

    //#region States

    const [clientName, setClientName] = useState('');
    const [isActive, setIsActive] = useState(true);
    const [projectCount, setProjectCount] = useState();

    const [modalOpen, setModalOpen] = useState(false);

    const [totalHours, setTotalHours] = useState(null);

    const [btnInformationClient, setBtnInformationClient] = useState(false)

    const [projectInfo, setProjectInfo] = useState(null);

    var modalText = `Tem certeza que seseja remover o cliente "${clientName}"`

    var params = useParams();
    var id = +params.id.replace(':', '');

    //#endregion

    //#region API Call

    function GetInformationClient() {
        client_controller.get(id, function (result) {
            setTotalHours(result.totalHours);
            setClientName(result.clientName);
            setIsActive(result.is_active);
            setProjectCount(result.projectsName.length);
            setProjectInfo(result.projectsName);
        });
    }

    useEffect(() => { GetInformationClient(); }, []);

    //#endregion

    return (
        <div style={{ height: '100%', overflow: 'hidden' }}>
            {modalOpen && <DeleteModal setOpenModal={setModalOpen} text={modalText} apiCall={client_controller} Id={id} GetInformationClient={GetInformationClient} />}
            <NavMenu />
            <a href="client-registration/new" type="button" style={{ marginLeft: "5%", marginTop: "7%" }} className="btn btn-secondary" >New Client</a>
            <div className="col-md-8" style={{ margin: "5%" }}>
                <div>
                    <span className="span_user_register_style">Client List</span>
                </div>
                <br />
                <div className="div_table_user_register_style">
                    <Table borderless dark responsive className="table_user_register_style">
                        <thead>
                            <tr>
                                <th className="th-Tabela-Register-Hours">Id</th>
                                <th className="th-Tabela-Register-Hours">Client Name</th>
                                <th className="th-Tabela-Register-Hours">Is Active</th>
                                <th className="th-Tabela-Register-Hours">Project Count</th>
                                <th className="th-Tabela-Register-Hours">Total Hours</th>
                                <th className="th-Tabela-Register-Hours">Edit Client</th>
                                <th className="th-Tabela-Register-Hours">Delete Client</th>
                            </tr>
                        </thead>
                        <tbody id="tbody">
                            {
                                <tr key={nanoid()}>
                                    <td onClick={() => { setBtnInformationClient(true) }} className="td-Tabela-Register-Hours td-Radius-Left">{id}</td>
                                    <td onClick={() => { setBtnInformationClient(true) }} className="td-Tabela-Register-Hours">{clientName}</td>
                                    <td onClick={() => { setBtnInformationClient(true) }} className="td-Tabela-Register-Hours">{isActive ? "Sim" : "Não"}</td>
                                    <td onClick={() => { setBtnInformationClient(true) }} className="td-Tabela-Register-Hours">{projectCount}</td>
                                    <td onClick={() => { setBtnInformationClient(true) }} className="td-Tabela-Register-Hours">{totalHours}</td>
                                    <td className="td-Tabela-Register-Hours"><a type="button"><i onClick={() => { window.location = `/client-registration/edit-client:${id}` }} className='bx bxs-edit-alt btn-Edit-Style'></i></a></td>
                                    <td className="td-Tabela-Register-Hours td-Radius-Right"><a type="button" onClick={() => { setModalOpen(true); setClientName(clientName); }}>{<i className='bx bx-x btn-delete-style'></i>}</a></td>
                                </tr>

                                // responseList.map((item, index) => {
                                //     return (
                                //         <tr key={nanoid()}>
                                //             <td className="td_left_user_register_style">{item.Id}</td>
                                //             <td>{item.Name}</td>
                                //             <td>{(item.is_active === true) ? "Yes" : "No"}</td>
                                //             <td>{item.HasProject}</td>
                                //             <td><a type="button" href={`/client-registration/edit-client:${item.Id}`}>Edit</a></td>
                                //             <td className="td_right_user_register_style"><a type="button" onClick={() => { setModalOpen(true); setClientName(item.Name); SetClientId(item.Id) }}>Delete</a></td>
                                //         </tr>
                                //     );
                            }
                        </tbody>
                    </Table>
                </div>
                <InformationClient trigger={btnInformationClient} setTrigger={setBtnInformationClient} projectInfo={projectInfo} />
            </div>
        </div >
    );
}