//Importo meus objetos para chamar a api
import { httpPost, httpDelete, httpGet } from "../Modules/httpHandler"

//Crio um objeto para exportar para todo o projeto
export var authentication_api = {
    authenticate: Authentication_Api,
}

//Defino o endpoint que chamo a API
//Defino uma função como callback
//Trato meu erro
function Authentication_Api(data, callback) {
    httpGet("v1/authenticated", data, function (sucess) {
        callback(sucess);
    }, function (error) {
        console.log(error);
    });
}