import React, { useState } from "react";
import { client_controller } from "../Controller/Cliente";


export default function CreateClient() {

    //#region States

    const [ClientName, setClientName] = useState("");

    //#endregion
    
    //#region API Call

    function postdata() {
        client_controller.create(ClientName);
    }

    //#endregion
    
    return (
        <div>
            <div className="col-md-12">
                <div style={{ padding: "5%" }}>
                    <a type="button" className="btn btn-secondary" href="/user-registration">Voltar</a>
                </div>
                <div style={{ paddingLeft: "5%" }}>
                    <div>
                        <h2 style={{ color: "white" }}>Client Registration</h2>
                    </div>
                    <br />
                    <div className="input-group has-validation" style={{ width: "50%" }}>
                        <input type="text" className="form-control" placeholder="User Name" id="ClientName" onChange={(x) => { setClientName(x.target.value); }}></input>
                        <div className="invalid-feedback">O nome do Cliente não pode ser vazio</div>
                    </div>
                    <br />
                    <a type="button" style={{ marginTop: "5%", fontSize: "20px" }} className="btn btn-secondary" id="submitButton" onClick={postdata}>Create</a>
                </div>
            </div>
        </div>
    );
}