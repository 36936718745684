//Importo meus objetos para chamar a api
import { httpPost, httpDelete, httpGet } from "../Modules/httpHandler"

//Crio um objeto para usar em todo o projeto
export var spentHour_api = {
    create: CreateSpentHours_Api,
    delete: DeleteSpentHours_Api,
    edit: EditSpentHours_Api,
    getUniqueSpentHour: GetUniqueSpentHour_Api,
    listTotalHours: ListTotalSpentHours_Api,
    list: ListSpentHours_Api,
    update: UpdateControllSpentHours_Api
}

//Recebo (parameters, callback) da controller
//Defino o endpoint que chamo a API
//Defino uma função como callback
//Trato meu erro
function CreateSpentHours_Api(parameters, callback) {
    httpPost("TimeSheet/InsertControlSpentHours", parameters, function (sucess) {
        callback(sucess)
    }, function (error) {
        alert("Falha ao inserir horas gastas");
    });
}

//Recebo (parameters, data, callback) da controller
//Defino o endpoint que chamo a API
//Defino uma função como callback
//Trato meu erro
function DeleteSpentHours_Api(parameters, data, callback) {
    httpDelete(`TimeSheet/DeleteControlSpentHours?id=${parameters.Id}`, data, function (sucess) {
        callback(sucess);
    }, function (error) {
        alert("Falha ao apagar horas gastas");
    });
}

//Recebo (parameters, data, callback) da controller
//Defino o endpoint que chamo a API
//Defino uma função como callback
//Trato meu erro
function GetUniqueSpentHour_Api(parameters, data, callback) {
    httpGet(`TimeSheet/GetSpentHours?id=${parameters}`, data, function (sucess) {
        callback(sucess);
    }, function (error) {
        alert("Falha ao coletar informações das horas gaastas");
    });
}

//Recebo (parameters, callback) da controller
//Defino o endpoint que chamo a API
//Defino uma função como callback
//Trato meu erro
function EditSpentHours_Api(paramaters, callback) {
    httpPost("TimeSheet/UpdateControlSpentHours", paramaters, function (sucess) {
        callback(sucess);
    }, function (error) {
        alert("Falha ao atualizar horas gastas");
    });
}

//Recebo (data, callback) da controller
//Defino o endpoint que chamo a API
//Defino uma função como callback
//Trato meu erro
function ListTotalSpentHours_Api(data, callback) {
    httpGet("TimeSheet/GetProjectInformation", data, function (sucess) {
        callback(sucess);
    }, function (error) {
        // alert("Falha ao listar as horas do projeto");
        console.log(error);
    });
}

//Recebo (parameters, data, callback) da controller
//Defino o endpoint que chamo a API
//Defino uma função como callback
//Trato meu erro
function ListSpentHours_Api(parameter, data, callback) {
    httpPost(`TimeSheet/ListControlSpentHours${parameter}`, data, function (sucess) {
        callback(sucess);
    }, function (error) {
        alert("falha ao listar as horas gastas");
    });
}

//Recebo (data, callback) da controller
//Defino o endpoint que chamo a API
//Defino uma função como callback
//Trato meu erro
function UpdateControllSpentHours_Api(data, callback) {
    httpPost("TimeSheet/UpdateControlSpentHours", data, function (sucess) {
        callback(sucess);
    }, function (error) {
        alert("Falha ao atualizar horas gastas");
    });
}