import _ from 'lodash';
import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import "../css/userRegistration.css";

import { nanoid } from 'nanoid';
import NavMenu from "./navMenu";

import EChartsReact from "echarts-for-react";

import DeleteModal from './delete-Modal';

import { spentHours_controller } from '../Controller/SpentHours';
import { helper } from '../Helper/helper';


export default function ControlSpentHours() {
    //#region States

    const [responseList, setResponseList] = useState([]);
    const [fliltredResponseList, setFliltredResponseList] = useState([]);
    const [clientName, setClientName] = useState('');
    const [modalOpen, setModalOpen] = useState(false);

    const [spentObject, setSpentObject] = useState();

    const [projectName, setProjectName] = useState('');
    const [spentHoursId, setSpentHoursId] = useState(0);
    const [searchUser, setSearchUser] = useState('');

    var modalText = `Tem certeza que seseja remover as horas gastas do usuário "${clientName}" no Projeto "${projectName}"`

    //#endregion

    //#region API Call

    function ListEntryHours() {
        spentHours_controller.listTotalHours(function (sucess) {
            setSpentObject(sucess)
        });

        spentHours_controller.list("", function (sucess) {
            var result = [];
            sucess.data.map((x) => {
                var arr = x.date.replace("T00:00:00", "").split("-");
                var trueDate = `${arr[2]}/${arr[1]}/${arr[0]}`;
                x.date = trueDate;
                result.push(x)
            });
            setFliltredResponseList(result);
            setResponseList(result);

        });
    }

    useEffect(() => { ListEntryHours(); }, []);

    //#endregion

    //#region Graph Config

    //Crio meu objeto de gráfico
    const option = {
        tooltip: {
            trigger: 'item'
        },
        legend: {
            //Defino meu tipo de gráfico
            type: 'scroll',
            orient: 'vertical',
            left: 'left',
            itemHeight: 26,
            itemWidth: 26,
            textStyle: {
                color: "rgb(255,255,255)",
                fontSize: 16
            }
        },
        series: [
            {
                name: 'Cliente',
                type: 'pie',
                radius: '60%',
                data: spentObject,
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                },
                darkMode: "true",
                height: "600px",
                label: {
                    fontSize: 16,
                    alignTo: "labelLine"
                }
            }
        ],
        darkMode: "true"
    };

    //#endregion

    return (
        <div style={{ overflowY: "auto", height: "100%" }}>
            <div style={{ position: "absolute", zIndex: "1" }}>
                {modalOpen && <DeleteModal setOpenModal={setModalOpen} apiCall={spentHours_controller} text={modalText} Id={spentHoursId} ListEntryHours={ListEntryHours} />}
            </div>
            <NavMenu />
            <div style={{ display: 'flex', position: 'relative', justifyContent: 'space-between' }}>
                <a href="/entry-control-spent-hours/new" type="button" style={{ marginLeft: "5%", marginTop: "5%", height: '10%' }} className="btn btn-secondary">Novo Registro de Horas</a>
            </div>
            <div className='col-md-11' style={{ marginLeft: "5%", marginTop: '3%' }}>

                <div className="input-group mb-3" style={{ width: '20%', marginLeft: '70%' }}>
                    <input type="text" style={{ width: "20%" }} className="form-control" placeholder="Usuário / Cliente" id="ProjectName" onChange={((x) => { setSearchUser(x.target.value); })}></input>
                    <button type="button" className="btn btn-secondary" style={{ zIndex: '0' }} onClick={() => {
                        setFliltredResponseList(responseList.filter((item) => {
                            console.log(item);
                            if (searchUser) {
                                return item.userName.toLowerCase().includes(searchUser.toLowerCase()) || item.clientName.toLowerCase().includes(searchUser.toLowerCase());
                            } else {
                                return responseList;
                            }
                        }));
                    }}>Buscar</button>
                </div>

                <div>
                    <span className="span_user_register_style">Lista de entrada de horas</span>
                </div>
                <br />
                <div style={{ display: 'flex' }}>
                    <div className="div_table_user_register_style" style={{ width: "90%" }}>
                        <Table borderless dark responsive className="table_user_register_style table-Register-Hours-Style">
                            <thead>
                                <tr>
                                    <th className="th-Tabela-Register-Hours">Id de entrada de hora</th>
                                    <th className="th-Tabela-Register-Hours">Nome do usuário</th>
                                    <th className="th-Tabela-Register-Hours">Data</th>
                                    <th className="th-Tabela-Register-Hours">Nome do cliente</th>
                                    <th className="th-Tabela-Register-Hours">Nome do projeto</th>
                                    <th className="th-Tabela-Register-Hours">Horas gastas</th>
                                    <th className="th-Tabela-Register-Hours">Editar</th>
                                    <th className="th-Tabela-Register-Hours">Apagar</th>
                                </tr>
                            </thead>
                            <tbody id="tbody">
                                {
                                    fliltredResponseList.map((item, index) => {
                                        return (
                                            <tr key={nanoid()}>
                                                <td className="td-Tabela-Register-Hours td-Radius-Left">{item.id}</td>
                                                <td className="td-Tabela-Register-Hours">{item.userName}</td>
                                                <td className="td-Tabela-Register-Hours">{item.date.replace('T00:00:00', '')}</td>
                                                <td className="td-Tabela-Register-Hours">{item.clientName}</td>
                                                <td className="td-Tabela-Register-Hours">{item.projectName}</td>
                                                <td className="td-Tabela-Register-Hours">{item.spentHours}</td>
                                                <td className="td-Tabela-Register-Hours"><a type="button">{<i className='bx bxs-edit-alt btn-Edit-Style' onClick={() => { window.location = `/entry-control-spent-hours/edit-spent-hours:${item.id}` }} ></i>}</a></td>
                                                <td className="td-Tabela-Register-Hours td-Radius-Right"><a type="button" onClick={() => { setModalOpen(true); setSpentHoursId(item.id); setClientName(item.userName); setProjectName(item.projectName); }}>{<i className='bx bx-x btn-delete-style'></i>}</a></td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
            <div style={{ height: "600px", marginTop: "10rem", marginLeft: "7.5rem" }}>
                <EChartsReact option={option} theme="auto" style={{ height: "600px" }}></EChartsReact>
            </div>
        </div >
    );
}