import { nanoid } from 'nanoid';
import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import "../css/userRegistration.css";
import DeleteModal from './delete-Modal';
import InformationProject from './information-Project';
import NavMenu from "./navMenu";

import { project_controller } from '../Controller/Project';

export default function ProjectResistration() {

    //#region States

    const [responseList, setResponseList] = useState([]);
    const [filtredResponseList, setFliltredResponseList] = useState([]);
    const [projectName, setProjectName] = useState('')
    const [projectID, setProjectId] = useState('');
    const [modalOpen, setModalOpen] = useState(false);

    const [searchUser, setSearchUser] = useState(null);

    const [btnInformationProject, setBtnInformationProject] = useState(null);

    const [userInfo, setUserInfo] = useState(null);

    var modalText = `Tem certeza que seseja remover o Projeto "${projectName}"`

    //#endregion

    //#region API Call

    function getInformationProject() {
        project_controller.get(function (sucess) {
            setResponseList(sucess.data);
            setFliltredResponseList(sucess.data);
        });
    }

    useEffect(() => { getInformationProject(); }, [])

    //#endregion

    return (
        <div>
            {modalOpen && <DeleteModal setOpenModal={setModalOpen} text={modalText} apiCall={project_controller} Id={projectID} getInformationProject={getInformationProject} />}
            <NavMenu />
            <a href="/project-registration/new" type="button" style={{ marginLeft: "5%", marginTop: "4.1%" }} className="btn btn-secondary">Novo projeto</a>
            <div className="col-md-8" style={{ margin: "5%" }}>
                <div className="input-group mb-3" style={{ width: '20%', marginLeft: '80%' }}>
                    <input type="text" style={{ width: "20%" }} className="form-control" placeholder="Projeto / Cliente" id="ProjectName" onChange={((x) => { setSearchUser(x.target.value); })}></input>
                    <button type="button" className="btn btn-secondary" style={{ zIndex: '0' }} onClick={() => {
                        setFliltredResponseList(responseList.filter((item) => {
                            if (searchUser) {
                                return item.projectName.toLowerCase().includes(searchUser.toLowerCase()) || item.clientName.toLowerCase().includes(searchUser.toLowerCase());
                            } else {
                                return responseList;
                            }
                        }));
                    }}>Buscar</button>
                </div>
                <div>
                    <span className="span_user_register_style">Lista de projetos</span>
                </div>
                <br />
                <div className="div_table_user_register_style" style={{ maxHeight: "390px" }}>
                    <Table borderless dark responsive className="table-Register-Hours-Style" >
                        <thead>
                            <tr>
                                <th className="th-Tabela-Register-Hours">Id do projeto</th>
                                <th className="th-Tabela-Register-Hours">Nome do projeto</th>
                                <th className="th-Tabela-Register-Hours">Nome do cliente</th>
                                <th className="th-Tabela-Register-Hours">Ativo</th>
                                <th className="th-Tabela-Register-Hours">Total de horas</th>
                                <th className="th-Tabela-Register-Hours">Editar</th>
                                <th className="th-Tabela-Register-Hours">Apagar</th>
                            </tr>
                        </thead>
                        <tbody id="tbody">
                            {
                                filtredResponseList.map((item, index) => {
                                    return (
                                        <tr key={nanoid()}>
                                            <td className="td-Tabela-Register-Hours td_left_user_register_style" onClick={() => { setUserInfo(item.userName); setBtnInformationProject(true) }}>{item.id}</td>
                                            <td className="td-Tabela-Register-Hours" onClick={() => { setUserInfo(item.userName); setBtnInformationProject(true); setProjectName(item.projectName); }}>{item.projectName}</td>
                                            <td className="td-Tabela-Register-Hours" onClick={() => { setUserInfo(item.userName); setBtnInformationProject(true); setProjectName(item.projectName); }}>{item.clientName}</td>
                                            <td className="td-Tabela-Register-Hours" onClick={() => { setUserInfo(item.userName); setBtnInformationProject(true); setProjectName(item.projectName); }}>{item.is_active ? "Sim" : "Não"}</td>
                                            <td className="td-Tabela-Register-Hours" onClick={() => { setUserInfo(item.userName); setBtnInformationProject(true); setProjectName(item.projectName); }}>{item.totalHours}</td>
                                            <td className="td-Tabela-Register-Hours"><a type="button">{<i className='bx bxs-edit-alt btn-Edit-Style' onClick={() => { window.location = `/project-registration/edit-project:${item.id}` }} ></i>}</a></td>
                                            <td className="td-Tabela-Register-Hours td_right_user_register_style"><a type="button" onClick={() => { setModalOpen(true); setProjectName(item.projectName); setProjectId(item.id) }}>{<i className='bx bx-x btn-delete-style'></i>}</a></td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </Table>
                </div>
            </div>
            <InformationProject trigger={btnInformationProject} setTrigger={setBtnInformationProject} userInfo={userInfo} projectName={projectName} />
        </div >
    );
}