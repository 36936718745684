import React, { useEffect, useState } from "react";
import { nanoid } from 'nanoid';
import 'boxicons';
import { Collapse, DropdownItem, DropdownMenu, DropdownToggle, Nav, Navbar, NavbarBrand, UncontrolledDropdown } from 'reactstrap';
import Logo from '../content/SkySoftware_Icone_Branco.png';
import '../css/nav-bar-new.css';
import '../css/nav-bar.css';

import { client_controller } from "../Controller/Cliente";
import { usuario_Controller } from "../Controller/Usuario";


export default function NavMenuNew() {

    //#region States

    //largura <800px
    var screenWidth = window.screen.availWidth;

    const [clientList, setClientList] = useState([]);

    //#endregion

    //#region API Call
    function generateChangePasswdToken() {
        usuario_Controller.generateChangePasswdToken();
    }

    function ListClient() {
        client_controller.list(function (sucess) {
            setClientList(sucess);
        })
    }

    useEffect(() => { ListClient(); }, []);

    //#endregion

    return (
        <div>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <Navbar expand="md" dark className="nav-Style" >
                <div>
                    <img src={Logo} className='nav-new-img' />
                    <NavbarBrand >TIME SHEET</NavbarBrand>
                </div>
                <div>
                    <Collapse navbar>
                        <Nav className="me-auto" navbar >
                            <div>
                                <UncontrolledDropdown >
                                    <DropdownToggle nav>
                                        <div>Cadastros</div>
                                    </DropdownToggle>

                                    <DropdownMenu dark end className="dropDownMenu-Style dropDownMenu-Style-List">
                                        <DropdownItem className="dropDownItem01-Style" href="/user-registration" style={{ marginTop: ".85rem" }}>Usuários</DropdownItem>
                                        <div className="dropDownItem02-Style" style={{ padding: '.8rem 2.5rem' }}>
                                            <Collapse navbar>
                                                <Nav className="me-auto" navbar >
                                                    <div>
                                                        <UncontrolledDropdown >
                                                            <DropdownToggle nav>
                                                                <div style={{ color: "white" }}>Clientes</div>
                                                            </DropdownToggle>

                                                            <DropdownMenu dark end className="dropDownMenu-Style dropDownMenu-Style-Inside">
                                                                <DropdownItem className="dropDownItem01-Style" href='/client-registration/new'>Novo Cliente</DropdownItem>
                                                                <div style={{ border: "solid rgba(71, 71, 71, 1) 1px" }} />
                                                                {clientList.map((item) => {
                                                                    return (
                                                                        <div key={nanoid()}>
                                                                            <DropdownItem className="dropDownItem01-Style" key={nanoid()} href={`/client-registration:${item.id}`} >{item.clientName}</DropdownItem>
                                                                            <div style={{ border: "solid rgba(71, 71, 71, 1) 1px" }} />
                                                                        </div>
                                                                    );
                                                                })}
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </div>
                                                </Nav>
                                            </Collapse>
                                        </div>
                                        <DropdownItem className="dropDownItem01-Style" href="/project-registration">Projetos</DropdownItem>
                                        <DropdownItem className="dropDownItem02-Style" href="/entry-control-spent-hours">Entrada de Horas</DropdownItem>
                                        <DropdownItem className="dropDownItem01-Style" href="/register-hours">Registrar Horas</DropdownItem>
                                    </DropdownMenu>

                                </UncontrolledDropdown>
                            </div>
                        </Nav>
                    </Collapse>
                </div>
                <div className="div-Collapse-Style">
                    <Collapse navbar>
                        <Nav className="me-auto" navbar >
                            <div>
                                <UncontrolledDropdown >
                                    <DropdownToggle nav>
                                        <div className="div-DropDownToggle">
                                            {localStorage.getItem('userName')}
                                            <br />
                                            {localStorage.getItem('userLogin')}
                                            <button className="btn-DropDownToggle-Style"> {<i className='bx bx-chevron-down' ></i>} </button>
                                        </div>
                                    </DropdownToggle>
                                    <DropdownMenu dark end className="dropDownMenu-Style">
                                        <DropdownItem className="dropDownItem01-Style" onClick={() => { generateChangePasswdToken(); }}> Redefinir Senha </DropdownItem>
                                        <DropdownItem className="dropDownItem02-Style" onClick={(() => { localStorage.setItem('userToken', ''); localStorage.setItem('userName', ''); localStorage.setItem('userLogin', '') })} href='/'> Sair</DropdownItem>
                                    </DropdownMenu>

                                </UncontrolledDropdown>
                            </div>
                        </Nav>
                    </Collapse>
                </div>
            </Navbar>
        </div>
    );


}