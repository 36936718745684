//Importo meus objetos para chamar a api
import { httpPost, httpDelete, httpGet } from "../Modules/httpHandler"

//Crio um objeto para usar em todo o projeto
export var cliente_api = {
    GetInformationClient: GetInformationClient,
    create: CreateClient,
    List: ListClients,
    edit: EditClient_Api,
    delete: DeleteClient_Api,
    getUniqueClient: GetUniqueClient_Api
}

//Recebo (parameters, data, callback) da controller
//Defino o endpoint que chamo a API
//Defino uma função como callback
//Trato meu erro
function GetInformationClient(parameters, data, callback) {
    httpGet(`TimeSheet/GetInfornationsClient?id=${parameters}`, data,
        function (sucess) {
            callback(sucess.data[0]);
        },
        function (error) {
            alert("Falha ao coletar informações do cliente");
        }
    );
}

//Recebo (parameters, callback) da controller
//Defino o endpoint que chamo a API
//Defino uma função como callback
//Trato meu erro
function CreateClient(parameters, data, callback) {
    httpPost(`TimeSheet/InsertClient?name=${parameters}`, data,
        function (sucess) {
            callback(sucess);
        },
        function (error) {
            console.log(error);
            alert("Falha ao criar cliente");
        }
    );
}

//Recebo (parameters, callback) da controller
//Defino o endpoint que chamo a API
//Defino uma função como callback
//Trato meu erro
function ListClients(parameters, callback) {
    httpGet("TimeSheet/GetInfornationsClient", parameters, function (sucess) {
        callback(sucess);
    }, function (error) {
        console.log("Erro ao carregar Lista de clientes");
    });
}

//Recebo (parameters, data, callback) da controller
//Defino o endpoint que chamo a API
//Defino uma função como callback
//Trato meu erro
function DeleteClient_Api(parameters, data, callback) {
    httpDelete(`TimeSheet/DeleteClient?id=${parameters.Id}`, data, function (sucess) {
        callback(sucess);
    }, function (error) {
        alert("Falha ao deletar o cliente");
    });
}

//Recebo (parameters, callback) da controller
//Defino o endpoint que chamo a API
//Defino uma função como callback
//Trato meu erro
function EditClient_Api(parameters, callback) {
    httpPost("TimeSheet/UpdateClient", parameters, function (sucess) {
        callback(sucess);
    }, function (error) {
        alert("Falha ao atualizar o cliente");
    });
}

//Recebo (parameters, data, callback) da controller
//Defino o endpoint que chamo a API
//Defino uma função como callback
//Trato meu erro
function GetUniqueClient_Api(parameters, data, callback) {
    httpGet(`TimeSheet/GetClient?id=${parameters}`, data, function (sucess) {
        callback(sucess);
    }, function (error) {
        alert("Falha ao coletar informações do cliente");
    });
}