import React from 'react';
import { Table } from 'reactstrap';
import "../css/userRegistration.css";


export default function InformationUser(props) {

    //#region Helper

    const handleOutsideClick = (modalId) => {
        if (modalId == 'popupId') {
            props.setTrigger(false);
        }
    }

    //#endregion

    return (props.trigger) ? (
        <div className="popup" id='popupId' onClick={((x) => { handleOutsideClick(x.target.id) })}>
            <div className="popupInner flex-box">
                <div className='div-Btn-Close-Popup'>
                    <button className="btn btn-secondary" onClick={() => props.setTrigger(false)}>X</button>
                </div>
                <div>
                    <form>
                        <fieldset >
                            <div className='col-md-12' >
                                <h1>Informaçoes do usuario {props.userName}</h1>
                                <label>Projetos em que o usuário faz parte</label>
                                <div style={{ overflow: 'auto', height: '500px' }}>
                                    <Table borderless dark responsive className="table-Register-Hours-Style" >
                                        <thead>
                                            <tr>
                                                <th className="th-Tabela-Register-Hours">Clientes</th>
                                                <th className="th-Tabela-Register-Hours">Projetos</th>
                                                <th className="th-Tabela-Register-Hours">Total de horas no projeto</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {props.projectInfo.map((item) => {
                                                return (
                                                    <tr>
                                                        <td className="td-Tabela-Informations td-Radius-Left">{item.clientName}</td>
                                                        <td className="td-Tabela-Informations">{item.projectName}</td>
                                                        <td className="td-Tabela-Informations td-Radius-Right">{item.totalHours}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>
        </div>
    ) : "";
}