import 'boxicons';
import { nanoid } from 'nanoid';
import React, { useEffect, useState } from "react";
import { Table } from 'reactstrap';
import '../css/register-Hours.css';
import NavMenuNew from "./navMenuNew";
import Popup from "./popUp";
import PopupEdit from "./popUpEdit";

import { spentHours_controller } from '../Controller/SpentHours';
import { helper } from '../Helper/helper';

import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Popper from '@mui/material/Popper';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';


export default function RegisterHours() {

    //#region States

    const [buttonPopupAdd, setButtonPopupAdd] = useState(false);
    const [buttonPopupEdit, setButtonPopupEdit] = useState(false);

    const [apiResponse, setApiResponse] = useState([]);

    const [date, setDate] = useState(new Date());

    const [formatedDate, setFormatedDate] = useState(helper.formatData(new Date()));

    const [spentHourId, setSpentHourId] = useState(null);

    const [projectId, setProjectId] = useState(0);
    const [projectName, setProjectName] = useState('');

    const [clientName, setClientName] = useState('');

    const [spentHours, setSpentHours] = useState(null);
    const [observation, setObservation] = useState('');

    //largura <800px
    var screenWidth = window.screen.availWidth;

    //#endregion

    //#region API Call

    function TotalHoursApiRequest(requestDate) {
        spentHours_controller.list(`?date=${requestDate}`, function (sucess) {

            setApiResponse(sucess.data);

            setProjectId(sucess.data.ProjectId);

            setProjectName(sucess.data.ProjectName);

            setClientName(sucess.data.ClientName);

            setSpentHours(sucess.data.SpentHours);

            setObservation(sucess.data.Observation);

        });
    }

    useEffect(() => { TotalHoursApiRequest(formatedDate) }, []);

    //#endregion

    if (screenWidth < 800) {
        return (
            <div>
                <div>
                    <NavMenuNew />
                </div>
                <link href='https://unpkg.com/boxicons@2.1.2/css/boxicons.min.css' rel='stylesheet' />

                <div className="col-md-12 div-Register-Hours">
                    <div className="div-Register_hours-date">
                        <br />
                        <div className="col-md-3">
                            <h5 className="hTag">Data</h5>
                            <div>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        value={date}
                                        inputFormat="dd/MM/yyyy"
                                        onChange={(newValue) => {
                                            setDate(newValue);
                                            TotalHoursApiRequest(helper.formatData(newValue));
                                            setFormatedDate(helper.formatData(newValue));
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                        <br />
                    </div>

                    <div className="div-Btn-Register-Hours-add-Spent-Hours">
                        <button className="btn btn-secondary btn-Register-Hours-add-Spent-Hours" onClick={() => { { setButtonPopupAdd(true); } }} > + </button>
                    </div>

                    <div className="div-Table-Register-Hours-Style">
                        <h4 className="hTag">Horas Gastas</h4>
                        <div className="div-Register-Hours-Table-Style">
                            <Table borderless dark responsive className="table-Register-Hours-Style" >
                                <thead>
                                    <tr>
                                        <th className="th-Tabela-Register-Hours" style={{ width: '20%' }}>Cliente</th>
                                        <th className="th-Tabela-Register-Hours" style={{ width: '20%' }}>Projeto</th>
                                        <th className="th-Tabela-Register-Hours" style={{ width: '20%' }}>Tempo</th>
                                        <th className="th-Tabela-Register-Hours" style={{ width: '15%' }}></th>
                                        {/* <th className="th-Tabela-Register-Hours" style={{ width: '15%' }}></th> */}
                                    </tr>
                                </thead>
                                <tbody >
                                    {apiResponse.map((item) => {
                                        return (
                                            <tr key={nanoid()} id="TrId" >
                                                <td className="td-Tabela-Register-Hours td-Radius-Left">{item.clientName}</td>
                                                {/* <td className="td-Tabela-Register-Hours"> <div style={{ overflowX: 'scroll', maxWidth: '300px' }}> {item.ProjectName} </div></td> */}
                                                <td className="td-Tabela-Register-Hours"> {item.projectName} </td>
                                                <td className="td-Tabela-Register-Hours">{item.spentHours}</td>
                                                <td className="td-Tabela-Register-Hours td-Radius-Right">{<i onClick={((x) => { setButtonPopupEdit(true); setSpentHourId(item.id); setClientName(item.clientName); setProjectName(item.projectName); setProjectId(item.projectId); setSpentHours(item.spentHours); setObservation(item.observation); })} className='bx bxs-edit-alt btn-Edit-Style'></i>}</td>
                                                {/* <td className="td-Tabela-Register-Hours td-Radius-Right"> <CollapseComponent projectName={item.projectName} spentHours={item.spentHours} totalSpentHours={item.totalHoursProjects} observation={item.observation} isOpen={popupStatus} /></td> */}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    <Popup trigger={buttonPopupAdd} setTrigger={setButtonPopupAdd} date={date} setDate={setDate} func={TotalHoursApiRequest} />
                    <PopupEdit trigger={buttonPopupEdit} setTrigger={setButtonPopupEdit} date={date} setDate={setDate} spentHourId={spentHourId} clientName={clientName} projectName={projectName} projectId={projectId} spentHours={spentHours} observation={observation} func={TotalHoursApiRequest} />
                </div>
            </div >
        );
    }
    return (
        <div style={{ overflowY: "auto", height: "100%" }}>
            <div>
                <NavMenuNew />
            </div>
            <link href='https://unpkg.com/boxicons@2.1.2/css/boxicons.min.css' rel='stylesheet' />

            <div className="col-md-12 div-Register-Hours">
                <div className="div-Register_hours-date">
                    <br />
                    <div className="col-md-3">
                        <h5 className="hTag">Data</h5>
                        <div>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    value={date}
                                    inputFormat="dd/MM/yyyy"
                                    onChange={(newValue) => {
                                        setDate(newValue);
                                        TotalHoursApiRequest(helper.formatData(newValue));
                                        setFormatedDate(helper.formatData(newValue));
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>
                    <br />
                </div>
                <div className="div-Btn-Register-Hours-add-Spent-Hours">
                    <button className="btn btn-secondary btn-Register-Hours-add-Spent-Hours" onClick={() => { { setButtonPopupAdd(true); } }} > + </button>
                </div>

                <div className="div-Table-Register-Hours-Style">
                    <h4 className="hTag">Horas Gastas</h4>
                    <div className="div-Register-Hours-Table-Style">
                        <Table borderless dark responsive className="table-Register-Hours-Style" style={{ zIndex: '10' }} >
                            <thead>
                                <tr>
                                    <th className="th-Tabela-Register-Hours" style={{ width: '25%' }}>Cliente</th>
                                    <th className="th-Tabela-Register-Hours" style={{ width: '20%' }}>Projeto</th>
                                    <th className="th-Tabela-Register-Hours" style={{ width: '20%' }}>Tempo</th>
                                    <th className="th-Tabela-Register-Hours" style={{ width: '15%' }}></th>
                                    <th className="th-Tabela-Register-Hours" style={{ width: '15%' }}></th>
                                </tr>
                            </thead>
                            <tbody >
                                {apiResponse.map((item) => {
                                    return (
                                        <tr key={nanoid()} id="TrId" >
                                            <td className="td-Tabela-Register-Hours td-Radius-Left">{item.clientName}</td>
                                            {/* <td className="td-Tabela-Register-Hours"> <div style={{ overflowX: 'scroll', maxWidth: '300px' }}> {item.ProjectName} </div></td> */}
                                            <td className="td-Tabela-Register-Hours"> {item.projectName} </td>
                                            <td className="td-Tabela-Register-Hours">{item.spentHours}</td>
                                            <td className="td-Tabela-Register-Hours" style={{ width: '150px' }}>{<i onClick={((x) => { setButtonPopupEdit(true); setSpentHourId(item.id); setClientName(item.clientName); setProjectName(item.projectName); setProjectId(item.projectId); setSpentHours(item.spentHours); setObservation(item.observation); })} className='bx bxs-edit-alt btn-Edit-Style'></i>}</td>
                                            <td className="td-Tabela-Register-Hours td-Radius-Right">
                                                <PopupState variant="popper" popupId="demo-popup-popper">
                                                    {(popupState) => (
                                                        <div>
                                                            <i className='bx bx-info-circle' {...bindToggle(popupState)} style={{ fontSize: '40px', cursor: 'pointer' }}></i>

                                                            {/* <Button variant="contained" {...bindToggle(popupState)} >
                                                                    <div style={{ fontSize: '30px' }}></div>
                                                                </Button> */}

                                                            <Popper {...bindPopper(popupState)} transition>
                                                                {({ TransitionProps }) => (
                                                                    <Fade {...TransitionProps} timeout={350}>
                                                                        <Paper>
                                                                            <Typography sx={{ p: 2 }}>
                                                                                <div>
                                                                                    <label>Nome do projeto</label>
                                                                                    <h4>{item.projectName}</h4>
                                                                                    <label>Descrição</label>
                                                                                    <h4>{item.observation}</h4>
                                                                                    <label>Tempo total do projeto</label>
                                                                                    <h4>{item.totalHoursProjects}</h4>


                                                                                    {/* <h4>Nome do projeto</h4>
                                                                                        <label>{item.projectName}</label>
                                                                                        <h4>Descrição</h4>
                                                                                        <label>{item.observation}</label>
                                                                                        <h4>Tempo total do projeto</h4>
                                                                                        <label>{item.totalHoursProjects}</label> */}
                                                                                </div>
                                                                            </Typography>
                                                                        </Paper>
                                                                    </Fade>
                                                                )}
                                                            </Popper>
                                                        </div>
                                                    )}
                                                </PopupState>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </div>
                </div>
                <Popup trigger={buttonPopupAdd} setTrigger={setButtonPopupAdd} date={date} setDate={setDate} func={TotalHoursApiRequest} />
                <PopupEdit trigger={buttonPopupEdit} setTrigger={setButtonPopupEdit} date={date} setDate={setDate} spentHourId={spentHourId} clientName={clientName} projectName={projectName} projectId={projectId} spentHours={spentHours} observation={observation} func={TotalHoursApiRequest} />
            </div>
        </div >
    );
}
