import React, { useEffect, useState } from "react";
import LogoImg from "../content/SkySoftware_Icone_Branco.png";
import "../css/login.css";

import { usuario_Controller } from "../Controller/Usuario";
import { authentication_controller } from "../Controller/Authentication";

function Login() {

    //#region States

    const [loginText, setLoginText] = useState("");
    const [passwordText, setPasswordText] = useState("");

    //#endregion

    //#region API Call

    function PostApiLogin() {
        usuario_Controller.login(loginText, passwordText);
    }

    useEffect(() => { authentication_controller.authenticate(); }, [])

    //#endregion

    return (
        <div style={{ height: "100%" }}>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <div className="col-md-12">
                <div className="div_login_logo">
                    <img src={LogoImg} className="img-fluid logo_login_style" id="photo" />
                </div>
                <div className="content_login_style" id="content_login_styleId">
                    <div className="col-md-6">
                        <div className="col-md-2 div_login_span_style">
                            <span className="span_login_style" id="spanId">Login</span>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-md-12">
                                <input type="email" className="form-control input_login_style" style={{ marginTop: "-50px" }} placeholder="Login" id="UserInput" onChange={(x) => { setLoginText(x.target.value) }} />
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-md-12">
                                <input type="password" className="form-control input_login_style" placeholder="Senha" id="PaswordInput" onChange={(x) => { setPasswordText(x.target.value) }}></input>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-md-12 div_btn_entrar_style">
                                <button type="submit" className="btn btn-secondary btn_login_style" onClick={() => { PostApiLogin() }} >Entrar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}
export default Login;