//importo meu objeto (usuario_api) para realizar as chamadas para API
import { usuario_api } from "../Api/Usuario";

//Crio um objeto para usar em todo o projeto
export var usuario_Controller = {
    create: UsuarioCreate_Controller,
    get: GetInformationUser_Controller,
    getUniqueUser: GetUniqueUser_Controller,
    delete: DeleteUser_Controller,
    update: UpdateUser_Controller,
    changePassword: UsuarioChangePasswd_Controller,
    login: UserLogin_Controller,
    generateChangePasswdToken: GenerateChangePasswdToken_Controller
}

//Recebo os parametros (userName, userPassword, userLogin, isAdmin) da tela
//Uso meu objeto para realizar a chamada para API
//Crio um objeto (data) onde passo para a API
//Trato minha função callback
//Faço a verificações dos parametros
function UsuarioCreate_Controller(userName, userPassword, userLogin, isAdmin, isActive) {
    if (userName && userPassword && userLogin) {

        var data = JSON.stringify({
            "name": userName,
            "password": userPassword,
            "login": userLogin,
            "isAdmin": isAdmin,
            "isActive": isActive
        });

        usuario_api.create(data, function (response) {
            alert("Usuário registrado com sucesso");
        })
    } else {
        alert('Nome do usuário, login ou senha não podem ser vazios');
    }

}

//Uso meu objeto para realizar a chamada para API
//Passo minha função callback para a tela
function GetInformationUser_Controller(callback) {
    var data = JSON.stringify("{}")
    usuario_api.get(data, function (sucess) {
        callback(sucess);
    })
}

//Recebo os parametros (password) da tela
//Uso meu objeto para realizar a chamada para API
//Trato minha função callback
//Faço a verificações dos parametros
function UsuarioChangePasswd_Controller(password) {
    if (password) {
        usuario_api.changePassword(password);
    } else {
        alert('Senha não pode ser vazia');
    }
}

//Recebo os parametros (props) da tela
//Uso meu objeto para realizar a chamada para API
//Trato minha função callback
function DeleteUser_Controller(props) {
    var data = JSON.stringify("{}")
    usuario_api.delete(props, data, function (sucess) {
        props.getUserInformation();
    });
}

//Recebo os parametros (id, userName, userLogin, userPassword, isAdmin) da tela
//Uso meu objeto para realizar a chamada para API
//Crio um objeto (data) onde passo para a API
//Trato minha função callback
//Faço a verificações dos parametros
function UpdateUser_Controller(id, userName, userLogin, isAdmin, isActive) {
    if (userName && userLogin) {
        var data = JSON.stringify({
            "id": id,
            "name": userName,
            "login": userLogin,
            "isAdmin": isAdmin,
            "is_active": isActive
        });
        usuario_api.update(data, function (sucess) {
            alert('Usuário editado com sucesso');
        });
    } else {
        alert('Nome do usuário, login ou senha não podem ser vazios');
    }
}

//Recebo os parametros (loginText, passwordText) da tela
//Uso meu objeto para realizar a chamada para API
//Crio um objeto (data) onde passo para a API
//Trato minha função callback
//Faço a verificações dos parametros
function UserLogin_Controller(loginText, passwordText) {
    if (loginText, passwordText) {

        var data = JSON.stringify({
            "email": loginText,
            "password": passwordText
        });
        usuario_api.login(data, function (sucess) {
            localStorage.setItem('userToken', sucess.data.token);
            localStorage.setItem('userName', sucess.data.user.name);
            localStorage.setItem('userLogin', sucess.data.user.login);

            sucess.data.user.is_admin ? window.location = '/user-registration' : window.location = '/register-hours'
        })
    } else {
        alert("login e senha não podem ser vazios");
    }
}

//Uso meu objeto para realizar a chamada para API
//Crio um objeto (data) onde passo para a API
//Trato minha função callback
function GenerateChangePasswdToken_Controller() {
    var data = JSON.stringify("{}");
    usuario_api.generateChangePasswd(data, function (sucess) {
        localStorage.setItem('changePasswdToken', sucess.data.token)
        alert('Uma mensagem foi enviada para seu e-mail, confira sua caixa de mensagens para redefinir sua senha');
    });
}

//Recebo os parametros (id) da tela
//Uso meu objeto para realizar a chamada para API
//Trato minha função callback
function GetUniqueUser_Controller(id, callback) {
    var data = JSON.stringify("{}");
    usuario_api.getUniqueUser(id, data, function (sucess) {
        callback(sucess);
    });
}