//Defino minha estrutura de chamadas get para API
//Exporto minha função para todo o projeto
export function httpGet(url, parametros, callback, onError) {
    var axios = require('axios');

    var config = {
        method: 'get',
        url: `https://timesheet-api.skysoftware.com.br/${url}`,
        headers: {
            'accept': '*/*',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('userToken')
        },
        data: parametros
    };

    axios(config)
        .then(function (response) {
            callback(response);
        })
        .catch(function (error) {
            onError(error);
        });
}

//Defino minha estrutura de chamadas post para API
//Exporto minha função para todo o projeto
export function httpPost(url, parametros, callback, onError) {

    var axios = require('axios');

    var config = {
        method: 'post',
        url: `https://timesheet-api.skysoftware.com.br/${url}`,
        headers: {
            'accept': '*/*',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('userToken')
        },
        data: parametros
    };

    axios(config)
        .then(function (response) {
            callback(response);
        })
        .catch(function (error) {
            onError(error);
        });
}

//Defino minha estrutura de chamadas delete para API
//Exporto minha função para todo o projeto
export function httpDelete(url, parametros, callback, onError) {
    var axios = require('axios');

    var config = {
        method: 'delete',
        url: `https://timesheet-api.skysoftware.com.br/${url}`,
        headers: {
            'accept': '*/*',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('userToken')
        },
        data: parametros
    };

    axios(config)
        .then(function (response) {
            callback(response);
        })
        .catch(function (error) {
            onError(error);
        });
}
