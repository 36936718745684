//Importo meus objetos para chamar a api
import { httpPost, httpDelete, httpGet } from "../Modules/httpHandler"

//Crio um objeto para usar em todo o projeto
export var usuario_api = {
    create: UsuaioCreate_Api,
    update: UsuarioUpdate_Api,
    get: GetInformationUser_Api,
    getUniqueUser: GetUniqueUser_Api,
    delete: DeleteUser_Api,
    changePassword: ChangePassword_Api,
    login: UserLogin_Api,
    generateChangePasswd: GenerateChangePasswdToken_Api
}

//Recebo (parameters, callback) da controller
//Defino o endpoint que chamo a API
//Defino uma função como callback
//Trato meu erro
function UsuaioCreate_Api(parameter, callback) {
    httpPost("TimeSheet/InsertUser", parameter, function (result) {
        callback(result);
    }, function (error) {
        alert("Falha ao criar usuário");
    });
}

//Recebo (data, callback) da controller
//Defino o endpoint que chamo a API
//Defino uma função como callback
//Trato meu erro
function UsuarioUpdate_Api(data, callback) {
    httpPost("TimeSheet/UpdateUser", data, function (result) {
        callback(result);
    }, function (result) {
        alert("Falha ao atualizar o usuário");
    });
}

//Recebo (parameters, callback) da controller
//Defino o endpoint que chamo a API
//Defino uma função como callback
//Trato meu erro
function GetInformationUser_Api(parameter, callback) {
    httpGet("TimeSheet/GetInfornationsUser", parameter, function (sucess) {
        callback(sucess);
    }, function (error) {
        alert("Falha ao coletar informações do usuário");
    });
}

//Recebo (parameter) da controller
//Defino o endpoint que chamo a API
//Defino uma função como callback
//Trato meu erro
function ChangePassword_Api(parameter) {
    httpPost(`v1/ChangePassword?password=${parameter}`,
        function (result) {
            alert('Senha alterada com sucesso');
        },
        function (result) {
            alert("Falha em alterar a senha");
        }
    )
}

//Recebo (parameter, data, callback) da controller
//Defino o endpoint que chamo a API
//Defino uma função como callback
//Trato meu erro
function DeleteUser_Api(parameter, data, callback) {
    httpDelete(`TimeSheet/DeleteUser?id=${parameter.Id}`, data, function (sucess) {
        callback(sucess);
    }, function (error) {
        alert("Falha em apagar usuário");
    });
}

//Recebo (data, callback) da controller
//Defino o endpoint que chamo a API
//Defino uma função como callback
//Trato meu erro
function UserLogin_Api(data, callback) {
    httpPost("v1/login", data, function (sucess) {
        callback(sucess);
    }, function (error) {
        alert("Login ou senha incorretos");
    });
}

//Recebo (data, callback) da controller
//Defino o endpoint que chamo a API
//Defino uma função como callback
//Trato meu erro
function GenerateChangePasswdToken_Api(data, callback) {
    httpPost(`v1/RedefinePassword?login=${localStorage.getItem('userLogin')}`, data, function (sucess) {
        callback(sucess);
    }, function (error) {
        alert("Falha em gerar o token para redefinir a senha");
    })
}

//Recebo (parameters, data, callback) da controller
//Defino o endpoint que chamo a API
//Defino uma função como callback
//Trato meu erro
function GetUniqueUser_Api(parameter, data, callback) {
    httpGet(`TimeSheet/GetUser?id=${parameter}`, data, function (sucess) {
        callback(sucess)
    }, function (error) {
        alert("Falha em coletar informações do usuário");
    });
}