export var helper = {
    formatHours : formatHours,
    formatData : formatDate
}


function formatHours(hour) {
    return `${ajustHours(hour.getHours())}:${ajustHours(hour.getMinutes())}`
}

function ajustHours(hour) {
    return (hour < 9) ? "0" + hour : hour;
}

function formatDate(date) {
    return `${ajustDate(date.getDate())}-${ajustDate(date.getMonth() + 1)}-${ajustDate(date.getFullYear())}`
}

function ajustDate(date) {
    return (date < 9) ? "0" + date : date;
}