import { nanoid } from 'nanoid';
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";

import { client_controller } from '../Controller/Cliente'
import { project_controller } from '../Controller/Project';

export default function CreateProject() {

    //#region States

    const [ProjectName, setProjectName] = useState("");
    const [clientName, setClientName] = useState("");
    const [clientList, setClientList] = useState([]);
    const [clientId, setClientId] = useState(0);

    //#endregion

    //#region API Call
    function ListClient() {
        client_controller.list(function (response) {
            setClientList(response);
        })
    }

    useEffect(() => { ListClient() }, []);

    function postdata() {
        project_controller.create(ProjectName, clientId);
    }

    //#endregion

    return (
        <div>
            <div className="col-md-12">
                <div style={{ padding: "5%" }}>
                    <a type="button" className="btn btn-secondary" href="/project-registration">Voltar</a>
                </div>
                <div style={{ paddingLeft: "5%" }}>
                    <div>
                        <h2 style={{ color: "white" }}>Project Registration</h2>
                    </div>
                    <br />
                    <div className="input-group has-validation" style={{ width: "50%" }}>
                        <input type="text" className="form-control" placeholder="Project Name" id="ProjectName" onChange={(x) => { setProjectName(x.target.value); }}></input>
                        <div className="invalid-feedback">O nome do Projeto não pode ser vazio</div>
                    </div>
                    <br />
                    <div className="input-group has-validation mb-3" style={{ width: "50%" }}>
                        <input type="text" className="form-control" placeholder="Client Name" value={clientName} id="ProjectName"></input>
                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary">
                                Select Client
                            </Dropdown.Toggle>

                            <Dropdown.Menu variant="dark">
                                {clientList.map((item) => {
                                    if (item.is_active == true) {
                                        return (
                                            <Dropdown.Item key={nanoid()} onClick={() => { setClientName(item.clientName); setClientId(item.id) }}>{item.clientName}</Dropdown.Item>
                                        );
                                    }
                                })}
                            </Dropdown.Menu>
                        </Dropdown>
                        <div className="invalid-feedback">O nome do Projeto não pode ser vazio</div>
                    </div>
                    <br />
                    <a type="button" style={{ marginTop: "5%", fontSize: "20px" }} className="btn btn-secondary" id="submitButton" onClick={postdata}>Create</a>
                </div>
            </div>
        </div>
    );
}