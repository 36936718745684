import React from "react";
import '../css/delete-User-Modal.css';

export default function DeleteModal(props) {

    //#region Api Call

    function SpentHoursDeleteUserApi() {
        props.apiCall.delete(props);
    }
    
    //#endregion

    //#region Helper

    const handleOutsideClick = (modalId) => {
        if (modalId == 'modalBackgroundId') {
            props.setOpenModal(false);
        }
    }

    //#endregion

    return (
        <div className="modalBackground" id="modalBackgroundId" onClick={(x) => { handleOutsideClick(x.target.id); }}  >
            <div className="modalContainer" >
                <div className="body" style={{ color: "white" }}>
                    <p>{props.text}</p>
                </div>
                <div className="footer" style={{ color: "white" }}>
                    <button onClick={() => { props.setOpenModal(false); }} id="cancelBtn">Cancelar</button>
                    <button onClick={() => { SpentHoursDeleteUserApi(props.Id); props.setOpenModal(false); }}>Remover</button>
                </div>
            </div>
        </div>
    );
}
