import _ from "lodash";
import React, { useEffect, useState } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { nanoid } from 'nanoid';

import { helper } from "../Helper/helper";
import { client_controller } from "../Controller/Cliente";
import { project_controller } from "../Controller/Project";
import { spentHours_controller } from "../Controller/SpentHours";

// import Box from '@mui/material/Box';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';


import '../css/popup.css';


export default function PopupEdit(props) {

    //#region States

    var spentHour = props.spentHours

    var obs = props.observation

    var splitedDate = new Date().toString().split(' ');
    var formatedHour = `${splitedDate[0]} ${splitedDate[1]} ${splitedDate[2]} ${splitedDate[3]} ${spentHour} ${splitedDate[5]} ${splitedDate[6]} ${splitedDate[7]} ${splitedDate[8]}`

    const [projectId, setProjectId] = useState(''); ///////

    const [spentHours, setSpentHours] = useState(''); ////////

    const [observation, setObservation] = useState(null); //////

    const [clientList, setClientList] = useState([]); /// api ///
    const [projectList, setProjectList] = useState([]); /// api ///

    const [hours, setHours] = useState(null); ///////

    const [date, setDate] = useState(null);

    const [formatedDate, setFormatedDate] = useState();

    //largura <800px
    var screenWidth = window.screen.availWidth;

    useEffect(() => { setObservation(obs); }, [props.observation])

    useEffect(() => { setHours(formatedHour); }, [props.spentHours])

    useEffect(() => { setDate(props.date) }, [props.date])

    //#endregion

    //#region Helper

    const handleOutsideClick = (modalId) => {
        if (modalId == 'popupId') {
            props.setTrigger(false);
        }
    }

    //#endregion

    //#region API Call

    function ListClient() {
        client_controller.list(function (sucess) {
            setClientList(sucess.filter(x => x.hasProject != 0));
        })
    }

    useEffect(() => { ListClient() }, []);


    function ListProject(selectedClientName) {

        if (selectedClientName) {
            project_controller.list(selectedClientName, clientList, function (sucess) {
                setProjectList(sucess);
            });
        } else {
            setProjectList([])
        }
    }

    function postDataApi() {
        spentHours_controller.update(props.spentHourId, projectId ? projectId : props.projectId, date, spentHours ? spentHours : props.spentHours, observation ? observation : props.observation, props);
    }

    //#endregion

    if (screenWidth < 800) {
        return (props.trigger) ? (
            <div className="popup" id='popupId' onClick={((x) => { handleOutsideClick(x.target.id) })}>
                <div className="popupInner flex-box">
                    <div>
                        <form>
                            <fieldset >
                                <div className='col-md-12'>
                                    <h4>Horas Gastas</h4>
                                    <br />
                                    <div className='div-Title-Popup-Style'>
                                        <div className='popup-Title-Client'>
                                            <h5>Cliente</h5>
                                        </div>
                                        <div className='popup-Title-Project'>
                                            <h5>Horas</h5>
                                        </div>
                                    </div>
                                    <div className='popup-Div-Client-Hour-Field'>
                                        <div className="input-group has-validation mb-3 popup-Div-Input-Client-Style">
                                            <div style={{ width: '100%', color: 'black' }}>
                                                <FormGroup>
                                                    <Input type="select" defaultValue={props.clientName} onChange={(x) => { ListProject(x.target.value) }} autoFocus={true} onFocus={(x) => { ListProject(x.target.value); }} tabIndex="0">
                                                        <option></option>
                                                        {clientList.map((item) => {
                                                            return (
                                                                <option value={item.clientName} >{item.clientName}</option>
                                                            );
                                                        })}
                                                    </Input>
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className="input-group has-validation mb-3 popup-Div-Hour-Field">
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <TimePicker
                                                    ampm={false}
                                                    openTo="hours"
                                                    views={['hours', 'minutes']}
                                                    inputFormat="HH:mm"
                                                    mask="__:__"
                                                    value={hours}
                                                    onChange={(newValue) => {
                                                        setHours(newValue);
                                                        setSpentHours(helper.formatHours(newValue))
                                                    }}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>
                                            {/* <input type="time" className="form-control" onChange={((x) => { setSpentHours(x.target.value + ":00"); })} ></input> */}
                                        </div>
                                    </div>

                                    <div className='div-Title-Popup-Style' style={{ marginTop: '10%' }}>
                                        <div className='popup-Div-Project-Title'>
                                            <h5>Projeto</h5>
                                        </div>
                                        <div style={{ marginLeft: '35%', marginTop: '10%' }}>
                                            <h5>Data</h5>
                                        </div>
                                    </div>
                                    <div className='popup-Div-Client-Hour-Field' style={{ marginBottom: '15%' }}>
                                        <div className="input-group has-validation mb-3 popup-Div-Input-Client-Style">
                                            <div style={{ width: '100%', color: 'black' }}>
                                                <FormGroup>
                                                    <Input type="select" value={props.projectName} required={true} onChange={(x) => { setProjectId(x.target.value); }} tabIndex="0">
                                                        {projectList.map((item) => {
                                                            if (item.name == props.projectName) {
                                                                return (
                                                                    <option value={item.id}>{item.projectName}</option>
                                                                );
                                                            }
                                                        })}
                                                        {projectList.map((item) => {
                                                            if (item.name !== props.projectName) {
                                                                return (
                                                                    <option value={item.id}>{item.projectName}</option>
                                                                );
                                                            }
                                                        })}
                                                    </Input>
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className="input-group has-validation mb-3 popup-Div-Hour-Field">
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    value={date}
                                                    inputFormat="dd/MM/yyyy"
                                                    onChange={(newValue) => {
                                                        setDate(newValue);
                                                        setFormatedDate(helper.formatData(newValue));
                                                    }}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='popup-Title-Description'>
                                            <h5>Descrição</h5>
                                        </div>
                                        <input type="text" className="form-control popup-Input-Description-Field" defaultValue={observation} onChange={((x) => { setObservation(x.target.value); })}></input>
                                    </div>
                                    <div className='popup-Div-Btn'>
                                        <div>
                                            <button type="button" className="btn btn-secondary popup-Btn-Cancel-Style" onClick={() => props.setTrigger(false)}>Cancelar</button>
                                        </div>
                                        <div className='popup-Div-Btn-Save'>
                                            <button type="button" className="btn btn-secondary popup-Btn-Save-Style" onClick={(() => { postDataApi(); })}>Salvar</button>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        ) : "";
    } else {
        return (props.trigger) ? (
            <div className="popup" id='popupId' onClick={((x) => { handleOutsideClick(x.target.id) })}>
                <div className="popupInner flex-box">
                    <div className='div-Btn-Close-Popup'>
                        <button className="btn btn-secondary" onClick={() => props.setTrigger(false)}>X</button>
                    </div>
                    <div>
                        <form>
                            <fieldset >
                                <div className='col-md-12'>
                                    <h4>Horas Gastas</h4>
                                    <br />
                                    <div className='div-Title-Popup-Style'>
                                        <div className='popup-Title-Client'>
                                            <h5>Cliente</h5>
                                        </div>
                                        <div className='popup-Title-Project'>
                                            <h5>Horas</h5>
                                        </div>
                                    </div>
                                    <div className='popup-Div-Client-Hour-Field'>
                                        <div className="input-group has-validation mb-3 popup-Div-Input-Client-Style">
                                            <div style={{ width: '100%', color: 'black' }}>
                                                <FormGroup>
                                                    <Input type="select" defaultValue={props.clientName} onChange={(x) => { ListProject(x.target.value) }} autoFocus={true} onFocus={(x) => { ListProject(x.target.value); }} tabIndex="0">
                                                        <option></option>
                                                        {clientList.map((item) => {
                                                            return (
                                                                <option value={item.clientName} >{item.clientName}</option>
                                                            );
                                                        })}
                                                    </Input>
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className="input-group has-validation mb-3 popup-Div-Hour-Field" style={{ marginTop: '5px' }}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <TimePicker
                                                    ampm={false}
                                                    openTo="hours"
                                                    views={['hours', 'minutes']}
                                                    inputFormat="HH:mm"
                                                    mask="__:__"
                                                    value={hours}
                                                    onChange={(newValue) => {
                                                        setHours(newValue);
                                                        setSpentHours(helper.formatHours(newValue))
                                                        if (helper.formatHours(newValue) > '08:00') {
                                                            alert('A hora selecionada é maior que 8 horas ');
                                                        }
                                                    }}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>
                                            {/* <input type="time" className="form-control" onChange={((x) => { setSpentHours(x.target.value + ":00"); })} ></input> */}
                                        </div>
                                        <div>
                                            <div className='popup-Title-Description' style={{ marginTop: '-33px' }}>
                                                <h5>Descrição</h5>
                                            </div>
                                            <input type="text" className="form-control popup-Input-Description-Field" defaultValue={observation} onChange={((x) => { setObservation(x.target.value); })}></input>
                                        </div>
                                    </div>

                                    <div className='div-Title-Popup-Style' >
                                        <div className='popup-Div-Project-Title'>
                                            <h5>Projeto</h5>
                                        </div>
                                        <div style={{ marginLeft: '42%', marginTop: '3%' }}>
                                            <h5>Data</h5>
                                        </div>
                                    </div>
                                    <div className='popup-Div-Client-Hour-Field' style={{ marginBottom: '15%' }}>
                                        <div className="input-group has-validation mb-3 popup-Div-Input-Client-Style">
                                            <div style={{ width: '100%', color: 'black' }}>
                                                <FormGroup>
                                                    <Input type="select" value={props.projectName} required={true} onChange={(x) => { setProjectId(x.target.value); }} tabIndex="0">
                                                        {projectList.map((item) => {
                                                            if (item.name == props.projectName) {
                                                                return (
                                                                    <option value={item.id}>{item.projectName}</option>
                                                                );
                                                            }
                                                        })}
                                                        {projectList.map((item) => {
                                                            if (item.name !== props.projectName) {
                                                                return (
                                                                    <option value={item.id}>{item.projectName}</option>
                                                                );
                                                            }
                                                        })}
                                                    </Input>
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className="input-group has-validation mb-3 popup-Div-Hour-Field">
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    value={date}
                                                    inputFormat="dd/MM/yyyy"
                                                    onChange={(newValue) => {
                                                        setDate(newValue);
                                                        setFormatedDate(helper.formatData(newValue));
                                                    }}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                    <div className='popup-Div-Btn'>
                                        <div>
                                            <button type="button" className="btn btn-secondary popup-Btn-Cancel-Style" onClick={() => props.setTrigger(false)}>Cancelar</button>
                                        </div>
                                        <div className='popup-Div-Btn-Save'>
                                            <button type="button" className="btn btn-secondary popup-Btn-Save-Style" onClick={(() => { postDataApi(); })}>Salvar</button>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        ) : "";
    }
}