//importo meu objeto (project_api) para realizar as chamadas para API
import { project_api } from "../Api/Project";

//Crio um objeto para usar em todo o projeto
export var project_controller = {
    list: ListProject_Controller,
    create: CreateProject_Controller,
    delete: DeleteProject_Controller,
    edit: EditProject_Controller,
    get: GetInformationProject_Controller,
    getUniqueProject: GetUniqueProject_Controller
}

//Recebo os parametros (selectedClientName, clientList) da tela
//Uso meu objeto para realizar a chamada para API
//Trato meu objeto e passo ele para minha função callback
function ListProject_Controller(selectedClientName, clientList, callback) {
    var projectList = [];
    var response = clientList.filter(x => x.clientName == selectedClientName);
    response.map((x) => { (x.projectsName.map((y) => { projectList.push(y) })) });

    callback(projectList);
}

//Recebo os parametros (ProjectName, clientId) da tela
//Uso meu objeto para realizar a chamada para API
//Crio um objeto (data) onde passo para a API
//Trato minha função callback
//Faço a verificações dos parametros
function CreateProject_Controller(ProjectName, clientId) {
    if (ProjectName && clientId) {
        var data = JSON.stringify({
            "id": 0,
            "name": ProjectName,
            "clientId": clientId
        })

        project_api.create(data, function (sucess) {
            alert("Cliente criado com sucesso");
        });
    } else {
        alert('Nome do projeto ou cliente não podem ser vazios');
    }
}

//Recebo os parametros (props) da tela
//Uso meu objeto para realizar a chamada para API
//Trato minha função callback
function DeleteProject_Controller(props) {
    var data = JSON.stringify("{}");

    project_api.delete(props, data, function (sucess) {
        alert("Projeto apagado com sucesso");
        props.getInformationProject();
    });
}


//Uso meu objeto para realizar a chamada para API
//Passo minha função callback para a tela
function GetInformationProject_Controller(callback) {

    var data = JSON.stringify("{}");

    project_api.get(data, function (sucess) {
        callback(sucess);
    });
}

//Recebo os parametros (id) da tela
//Uso meu objeto para realizar a chamada para API
//Passo minha função callback para a tela
function GetUniqueProject_Controller(id, callback) {
    var data = JSON.stringify("{}");

    project_api.getUniqueProject(id, data, function (sucess) {
        callback(sucess);
    })
}

//Recebo os parametros (id, projectName, clientId, isActive) da tela
//Uso meu objeto para realizar a chamada para API
//Crio um objeto (data) onde passo para a API
//Trato minha função callback
//Faço a verificações dos parametros
function EditProject_Controller(id, projectName, clientId, isActive) {
    if (projectName && clientId) {
        var data = JSON.stringify({
            "id": id,
            "name": projectName,
            "clientId": clientId,
            "is_active": isActive
        })
        project_api.edit(data, function (sucess) {
            alert("Projeto editado com sucesso");
        });
    } else {
        alert('Nome do projeto ou cliente não podem ser vazios')
    }
}