//Importo meus objetos para chamar a api
import { httpPost, httpDelete, httpGet } from "../Modules/httpHandler"

//Crio um objeto para usar em todo o projeto
export var project_api = {
    create: CreateProject_Api,
    delete: DeleteProject_Api,
    edit: EditProject_Api,
    get: GetProjectInformation_Api,
    getUniqueProject: GetUniqueProject_Api
}

//Recebo (parameters,callback) da controller
//Defino o endpoint que chamo a API
//Defino uma função como callback
//Trato meu erro
function CreateProject_Api(parameters, callback) {
    httpPost("TimeSheet/InsertProject", parameters, function (sucess) {
        callback(sucess);
    }, function (error) {
        alert("Falha ao criar projeto");
    });
}

//Recebo (parameters, data, callback) da controller
//Defino o endpoint que chamo a API
//Defino uma função como callback
//Trato meu erro
function DeleteProject_Api(parameters, data, callback) {
    httpDelete(`TimeSheet/DeleteProject?id=${parameters.Id}`, data, function (sucess) {
        callback(sucess);
    }, function (error) {
        alert("Falha ao apagar projeto");
    });
}

//Recebo (data, callback) da controller
//Defino o endpoint que chamo a API
//Defino uma função como callback
//Trato meu erro
function GetProjectInformation_Api(data, callback) {
    httpGet("TimeSheet/GetProjectInformation", data, function (sucess) {
        callback(sucess);
    }, function (error) {
        alert("Falha ao coletar informações do projeto");
    });
}

//Recebo (parameters, data, callback) da controller
//Defino o endpoint que chamo a API
//Defino uma função como callback
//Trato meu erro
function GetUniqueProject_Api(parameters, data, callback) {
    httpGet(`TimeSheet/GetProject?id=${parameters}`, data, function (sucess) {
        callback(sucess);
    }, function (error) {
        alert("Falha ao coletar informações do projeto");
    });
}

//Recebo (data, callback) da controller
//Defino o endpoint que chamo a API
//Defino uma função como callback
//Trato meu erro
function EditProject_Api(data, callback) {
    httpPost("TimeSheet/UpdateProject", data, function (sucess) {
        callback(sucess);
    }, function (error) {
        alert("Falha ao atualizar o projeto");
    });
}