import React, { useEffect, useState } from "react";
import 'boxicons';
import { Collapse, DropdownItem, DropdownMenu, DropdownToggle, Nav, Navbar, NavbarBrand, UncontrolledDropdown } from 'reactstrap';
import Logo from '../content/SkySoftware_Icone_Branco.png';
import '../css/nav-bar-new.css';

import { usuario_Controller } from "../Controller/Usuario";

export default function NavMenuNew() {

    //#region States

    //largura <800px
    var screenWidth = window.screen.availWidth;

    //#endregion

    //#region States

    function generateChangePasswdToken() {
        usuario_Controller.generateChangePasswdToken();
    }

    //#endregion

    if (screenWidth < 800) {
        return (
            <div>
                <Navbar expand="md" dark className="nav-Style" >
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <img src={Logo} className='nav-new-img' />
                            <NavbarBrand >TIME SHEET</NavbarBrand>
                        </div>
                    </div>
                    <div className="div-Collapse-Style">
                        <Collapse navbar>
                            <Nav className="me-auto" navbar >
                                <div>
                                    <UncontrolledDropdown >
                                        <DropdownToggle nav>
                                            <div className="div-DropDownToggle">
                                                <button className="btn-DropDownToggle-Style"> {<i className='bx bx-menu'></i>} </button>
                                            </div>
                                        </DropdownToggle>

                                        <DropdownMenu dark end className="dropDownMenu-Style">
                                            <DropdownItem className="dropDownItem01-Style" onClick={() => { generateChangePasswdToken(); }}> Redefinir Senha </DropdownItem>
                                            <DropdownItem className="dropDownItem02-Style" onClick={(() => { localStorage.setItem('userToken', ''); localStorage.setItem('userName', ''); localStorage.setItem('userLogin', '') })} href='/'> Sair</DropdownItem>
                                        </DropdownMenu>

                                    </UncontrolledDropdown>
                                </div>
                            </Nav>
                        </Collapse>
                    </div>
                </Navbar>
            </div>
        );
    } else {
        return (
            <div>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <Navbar expand="md" dark className="nav-Style" >
                    <div>
                        <img src={Logo} className='nav-new-img' />
                        <NavbarBrand >TIME SHEET</NavbarBrand>
                    </div>
                    <div className="div-Collapse-Style">
                        <Collapse navbar>
                            <Nav className="me-auto" navbar >
                                <div>
                                    <UncontrolledDropdown >
                                        <DropdownToggle nav>
                                            <div className="div-DropDownToggle">
                                                {localStorage.getItem('userName')}
                                                <br />
                                                {localStorage.getItem('userLogin')}
                                                <button className="btn-DropDownToggle-Style"> {<i className='bx bx-chevron-down' ></i>} </button>
                                            </div>
                                        </DropdownToggle>

                                        <DropdownMenu dark end className="dropDownMenu-Style">
                                            <DropdownItem className="dropDownItem01-Style" onClick={() => { generateChangePasswdToken(); }}> Redefinir Senha </DropdownItem>
                                            <DropdownItem className="dropDownItem02-Style" onClick={(() => { localStorage.setItem('userToken', ''); localStorage.setItem('userName', ''); localStorage.setItem('userLogin', '') })} href='/'> Sair</DropdownItem>
                                        </DropdownMenu>

                                    </UncontrolledDropdown>
                                </div>
                            </Nav>
                        </Collapse>
                    </div>
                </Navbar>
            </div>
        );
    }

}