import "bootstrap/dist/css/bootstrap.min.css";
import React from 'react';
import ReactDOM from 'react-dom/client';
import Rota from './routes';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <div>
        <Rota />
    </div>
);
