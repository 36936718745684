import { ConstructionOutlined } from "@mui/icons-material";
import _ from "lodash";
import { nanoid } from 'nanoid';
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";


import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';


import { client_controller } from "../Controller/Cliente";
import { spentHours_controller } from "../Controller/SpentHours";

import { helper } from "../Helper/helper";
import { project_controller } from "../Controller/Project";


export default function CreateControlSpentHours() {

    //#region States

    const [clientId, setClientId] = useState(0);

    const [clientName, setClientName] = useState('');
    const [responseList, setResponseList] = useState([]);

    const [projectId, setProjectId] = useState(0);

    const [projectName, setProjectName] = useState('')
    const [projectList, setProjectList] = useState([]);


    const [spentHours, setSpentHours] = useState('');

    const [date, setDate] = useState('');
    const [hours, setHours] = useState(null);

    const [observation, setObservation] = useState(null);

    //#endregion

    //#region Chamadas Api

    function ListClient() {
        client_controller.list(function (response) {

            var projects = response.map((x) => { return x.projectsName })

            var clientList = [];

            for (let index = 0; index < projects.length; index++) {
                if (projects[index][0].projectName !== undefined) {
                    clientList.push(response[index])
                }
            }

            setResponseList(clientList);
        })
    }
    useEffect(() => { ListClient(); }, []);

    function ListProject(selectedClientName) {
        project_controller.list(selectedClientName, responseList, function (response) {
            setProjectList(response)
        });
    }

    function postdata() {
        spentHours_controller.create(projectId, date, spentHours, observation, function (sucess) {
            alert(sucess.data);
        });
    }

    //#endregion

    return (
        <div>
            <div className="col-md-12">
                <div style={{ paddingLeft: "5%", paddingTop: "3%", paddingBottom: "3%" }}>
                    <a type="button" className="btn btn-secondary" href="/entry-control-spent-hours">Voltar</a>
                </div>
                <div style={{ paddingLeft: "5%" }}>
                    <div>
                        <h2 style={{ color: "white" }}>Nova entrada de horas</h2>
                    </div>
                    <br />
                    <h5 style={{ color: "white" }}>Nome do cliente</h5>
                    <div className="input-group has-validation mb-3" style={{ width: "50%" }}>
                        <input type="text" className="form-control" placeholder="Nome do cliente" value={clientName} id="ClientName"></input>
                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary">
                                Selecionar cliente
                            </Dropdown.Toggle>

                            <Dropdown.Menu variant="dark">
                                {responseList.map((item) => {
                                    return (
                                        <Dropdown.Item key={nanoid()} onClick={() => { setClientName(item.clientName); setClientId(item.id); ListProject(item.clientName); }}>{item.clientName}</Dropdown.Item>
                                    );
                                })}
                            </Dropdown.Menu>
                        </Dropdown>
                        <div className="invalid-feedback">O nome do Projeto não pode ser vazio</div>
                    </div>
                    <br />
                    <h5 style={{ color: "white" }}>Nome do projeto</h5>
                    <div className="input-group has-validation mb-3" style={{ width: "50%" }}>
                        <input type="text" className="form-control" placeholder="Nome do projeto" value={projectName} id="ProjectName"></input>
                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary">
                                Selecionar projeto
                            </Dropdown.Toggle>

                            <Dropdown.Menu variant="dark">
                                {projectList.map((item) => {
                                    return (
                                        <Dropdown.Item key={nanoid()} onClick={() => { setProjectName(item.projectName); setProjectId(item.projectId); }}>{item.projectName}</Dropdown.Item>
                                    );
                                })}
                            </Dropdown.Menu>
                        </Dropdown>
                        <div className="invalid-feedback">O nome do Projeto não pode ser vazio</div>
                    </div>
                    <br />
                    <h5 style={{ color: "white" }}>Observação</h5>
                    <div className="input-group" style={{ width: "50%" }}>
                        <input type="text" className="form-control" placeholder="Observação" id="ClientName" onChange={(x) => { setObservation(x.target.value); }}></input>
                    </div>
                    <br />
                    <h5 style={{ color: "white" }}>Horas gastas</h5>
                    <div className="input-group has-validation mb-3" style={{ width: "50%" }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <TimePicker
                                ampm={false}
                                openTo="hours"
                                views={['hours', 'minutes']}
                                inputFormat="HH:mm"
                                mask="__:__"
                                value={hours}
                                onChange={(newValue) => {
                                    if (helper.formatHours(newValue) > '08:00') {
                                        alert('A hora selecionada é maior que 8 horas ');
                                    }
                                    setHours(newValue);
                                    setSpentHours(helper.formatHours(newValue));
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </div>
                    <br />
                    <h5 style={{ color: "white" }}>Data</h5>
                    <div className="input-group has-validation mb-3" style={{ width: "50%" }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                value={date}
                                inputFormat="dd/MM/yyyy"
                                onChange={(newValue) => {
                                    setDate(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </div>
                    <br />
                    <a type="button" style={{ marginTop: "2%", fontSize: "20px" }} className="btn btn-secondary" id="submitButton" onClick={() => { postdata(); }}>Registrar</a>
                </div>
            </div>
        </div>
    );
}