import React, { useState } from "react";
import LogoImg from "../content/SkySoftware_Icone_Branco.png";
import "../css/change-Password.css";
import { usuario_Controller } from "../Controller/Usuario";


export default function ChangePassword() {

    //#region States

    const [newPasswd, setNewPasswd] = useState('');
    const [newPasswdConfirmation, setNewPasswdConfirmation] = useState('');

    //#endregion

    //#region API Call

    function changePasswd() {
        if (newPasswd == newPasswdConfirmation) {
            usuario_Controller.changePassword(newPasswd);
        } else {
            alert('As senhas devem ser iguais');
        }
    }

    //#endregion
    
    return (
        <div className="col-md-12">
            <div className="div_style_change_password" style={{ height: "100%" }}>
                <div className="col-md-6">
                    <div className="div_logo_login_style">
                        <img src={LogoImg} className="img-fluid logo_style_change_password" id="photo" />
                        <br />
                        <div className="col-md-12 div_h1_style_change_password">
                            <h1 style={{ color: "white" }}>Redefinir Senha</h1>
                        </div>
                        <br />
                        <div className="col-md-12 ">
                            <div className="col-md-12 div_input_style_change_password">
                                <div className="row form-floating mb-3">
                                    <input type="password" className="form-control " placeholder="Nova senha" id="NovaSenha" onChange={(x) => { setNewPasswd(x.target.value) }} style={{ maxWidth: "60%" }}></input>
                                    <label htmlFor="NovaSenha">Nova senha</label>
                                    <div className="col-md-3">
                                        <button className="btn btn-secondary" type="button" onClick={() => { changePasswd() }} style={{ height: "98%" }}>Random Password</button>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="col-md-12 div_input_style_change_password">
                                <div className="row form-floating mb-3">
                                    <input type="password" className="form-control" placeholder="Confirme sua senha" id="ConfirmarNovaSenha" onChange={(x) => { setNewPasswdConfirmation(x.target.value); }} style={{ width: "60%" }}></input>
                                    <label htmlFor="ConfirmarNovaSenha">Confirmar nova senha</label>
                                </div>
                            </div>
                            <br />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <button type="button" className="btn btn-secondary button_style_change_password" style={{ marginLeft: "20%" }} onClick={() => { changePasswd(); }} >Redefinir senha</button>
                    </div>
                </div>
            </div>
        </div >
    );
}
