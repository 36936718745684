//importo meu objeto (cliente_api) para realizar as chamadas para API
import { cliente_api } from "../Api/Cliente";

//Crio um objeto para usar em todo o projeto
export var client_controller = {
    get: GetInformationClient_Controller,
    getUniqueClient: GetUniqueClient,
    create: CreateClient_Controler,
    list: ListClients_controller,
    edit: EditClient_Controller,
    delete: DeleteClient_Controller
}

//Recebo os parametros (id) da tela
//Uso meu objeto para realizar a chamada para API
//Passo minha função callback para a tela
function GetInformationClient_Controller(id, callback) {

    var data = JSON.stringify("{}")

    cliente_api.GetInformationClient(id, data, function (sucess) {
        callback(sucess);
    });
}

//Recebo os parametros (parameters) da tela
//Uso meu objeto para realizar a chamada para API
//Trato minha função callback
//Faço a verificações dos parametros
function CreateClient_Controler(parameters) {
    var data = JSON.stringify("{}");
    if (parameters) {
        cliente_api.create(parameters, data, function (sucess) {
            alert(sucess.data);
        })
    } else {
        alert('Nome do cliente não pode ser vazio');
    }
}

//Uso meu objeto para realizar a chamada para API
//Passo minha função callback para minha tela
function ListClients_controller(callback) {

    var data = JSON.stringify("{}")

    cliente_api.List(data, function (response) {

        response = response.data.filter(x => x.is_active == true);
        
        // var projects = response.map((x) => { return x.projectsName })

        // var clientList = [];

        // for (let index = 0; index < projects.length; index++) {
        //     if (projects[index][0].projectName !== undefined) {
        //         clientList.push(response[index])
        //     }
        // }

        callback(response);
    });
}

//Recebo os parametros (props) da tela
//Uso meu objeto para realizar a chamada para API
//Trato minha função callback
function DeleteClient_Controller(props) {

    var data = JSON.stringify("{}");

    cliente_api.delete(props, data, function () {
        alert('Cliente Apagado com sucesso')
        props.GetInformationClient();
        window.location = '/user-registration';
    });
}

//Recebo os parametros (id, clientName, isActive) da tela
//Uso meu objeto para realizar a chamada para API
//Crio um objeto (data) onde passo para a API
//Trato minha função callback
//Faço a verificações dos parametros
function EditClient_Controller(id, clientName, isActive) {
    if (clientName) {
        var data = JSON.stringify({
            "id": id,
            "name": clientName,
            "is_active": isActive
        });
        cliente_api.edit(data, function (sucess) {
            alert(sucess.data)
        });

    } else {
        alert('Nome do cliente não pode ser vazio');
    }
}

//Recebo os parametros (id) da tela
//Uso meu objeto para realizar a chamada para API
//Passo minha função callback para a tela
function GetUniqueClient(id, callback) {
    var data = JSON.stringify("{}");
    cliente_api.getUniqueClient(id, data, function (sucess) {
        callback(sucess);
    })
}