import { nanoid } from 'nanoid';
import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import "../css/userRegistration.css";
import InformationUser from './information-User';
import NavMenu from "./navMenu";

import DeleteModal from './delete-Modal';

import { usuario_Controller } from '../Controller/Usuario';

export default function UserRegistration() {

    //#region States

    const [responseList, setResponseList] = useState([]);
    const [filtredResponseList, setFliltredResponseList] = useState([]);
    const [userName, setUserName] = useState('');
    const [userId, SetUserId] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);

    const [searchUser, setSearchUser] = useState('');

    const [btnInformationUser, setBtnInformationUser] = useState(null);
    const [projectInfo, setProjectInfo] = useState(null);

    var modalText = `Tem certeza que seseja remover o usuário "${userName}"`

    //#endregion

    //#region API Call

    function getUserInformation() {
        usuario_Controller.get(function (sucess) {
            setResponseList(sucess.data);
            setFliltredResponseList(sucess.data);
        })
    }

    useEffect(() => {
        getUserInformation();
    }, [])

    function informationModal(item) {
        setProjectInfo(item.project);
        setBtnInformationUser(true);
        setUserName(item.userName);
    }

    //#endregion

    return (
        <div>
            {modalOpen && <DeleteModal setOpenModal={setModalOpen} text={modalText} apiCall={usuario_Controller} Id={userId} getUserInformation={getUserInformation} />}
            <NavMenu />
            <a href="user-registration/new" type="button" style={{ marginLeft: "5%", marginTop: "5%" }} className="btn btn-secondary" >Novo usuário</a>
            <div className="col-md-8" style={{ margin: "5%" }}>
                <div>
                    <div className="input-group mb-3 div_search_style">
                        <input type="text" style={{ width: "20%" }} className="form-control" placeholder="Nome / Login" id="ProjectName" onChange={((x) => { setSearchUser(x.target.value); })}></input>
                        <button type="button" className="btn btn-secondary btn_search_style" style={{ zIndex: '0' }} onClick={() => {
                            setFliltredResponseList(responseList.filter((item) => {
                                if (searchUser) {
                                    return item.userName.toLowerCase().includes(searchUser.toLowerCase()) || item.login.toLowerCase().includes(searchUser.toLowerCase());
                                } else {
                                    return responseList;
                                }
                            }));
                        }}>Buscar</button>
                    </div>
                    <span className="span_user_register_style">Lista de Usuarios</span>
                </div>
                <br />
                <div className="div_table_user_register_style" style={{ height: "24rem" }}>
                    <Table borderless dark responsive className="table-Register-Hours-Style" >
                        <thead>
                            <tr>
                                <th className="th-Tabela-Register-Hours">Id</th>
                                <th className="th-Tabela-Register-Hours">Nome</th>
                                <th className="th-Tabela-Register-Hours">Login</th>
                                <th className="th-Tabela-Register-Hours">Administrador</th>
                                <th className="th-Tabela-Register-Hours">Ativo</th>
                                <th className="th-Tabela-Register-Hours">Editar</th>
                                <th className="th-Tabela-Register-Hours">Remover</th>
                            </tr>
                        </thead>
                        <tbody id="tbody">
                            {
                                filtredResponseList.map((item, index) => {
                                    return (
                                        <tr key={nanoid()} >
                                            <td onClick={() => { informationModal(item); }} className="td-Tabela-Register-Hours td_left_user_register_style" >{item.id}</td>
                                            <td onClick={() => { informationModal(item); }} className="td-Tabela-Register-Hours">{item.userName}</td>
                                            <td onClick={() => { informationModal(item); }} className="td-Tabela-Register-Hours">{item.login}</td>
                                            <td onClick={() => { informationModal(item); }} className="td-Tabela-Register-Hours">{(item.is_admin) ? "Sim" : "Não"}</td>
                                            <td onClick={() => { informationModal(item); }} className="td-Tabela-Register-Hours">{(item.is_active) ? "Sim" : "Não"}</td>
                                            <td className="td-Tabela-Register-Hours"><a type="button">{<i className='bx bxs-edit-alt btn-Edit-Style' onClick={() => { window.location = `/user-registration/edit-user:${item.id}` }} ></i>}</a></td>
                                            <td className="td-Tabela-Register-Hours td_right_user_register_style"><a type="button" onClick={() => { setModalOpen(true); setUserName(item.userName); SetUserId(item.id) }}>{<i className='bx bx-x btn-delete-style'></i>}</a></td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </Table>
                </div>
                <InformationUser trigger={btnInformationUser} setTrigger={setBtnInformationUser} projectInfo={projectInfo} userName={userName} />
            </div>
        </div >
    )
}