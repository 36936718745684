import { nanoid } from 'nanoid';
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { client_controller } from '../Controller/Cliente';
import { project_controller } from '../Controller/Project';

export default function EditProject() {

    //#region States

    const [projectName, setProjectName] = useState('');
    const [clientName, setClientName] = useState('');
    const [clientId, setClientId] = useState(0);
    const [clientList, setClientList] = useState([]);
    const [isActive, setIsActive] = useState(null);

    var params = useParams();
    var id = +params.id.replace(':', '');

    //#endregion

    //#region API Call

    function ListClient() {
        client_controller.list(function (sucess) {
            var Clients = sucess.filter(x => x.projectsName.length >= 1);
            setClientList(Clients)
        })
    }

    useEffect(() => { ListClient() }, []);

    function getApiData() {
        project_controller.getUniqueProject(id, function (sucess) {
            setClientId(sucess.data.clientId);
            setClientName(sucess.data.clientName);
            setProjectName(sucess.data.name);
            setIsActive(sucess.data.is_active);
        });
    }

    useEffect(() => { getApiData(); }, []);

    function postdata() {
        project_controller.edit(id, projectName, clientId, isActive)
    }

    //#endregion

    return (
        <div>
            <div className="col-md-12">
                <div style={{ padding: "5%" }}>
                    <a type="button" className="btn btn-secondary" id="submitButton" href="/project-registration">Voltar</a>
                </div>
                <div style={{ paddingLeft: "5%" }}>
                    <div>
                        <h2 style={{ color: "white" }}>Edit Project</h2>
                    </div>
                    <br />
                    <div className="input-group has-validation" style={{ width: "50%" }}>
                        <input type="text" className="form-control" placeholder="Project Name" id="ProjectName" value={projectName} onChange={(x) => { setProjectName(x.target.value); }}></input>
                        <div className="invalid-feedback">O nome do cliente não pode ser vazio</div>
                    </div>
                    <br />
                    <div className="input-group has-validation mb-3" style={{ width: "50%" }}>
                        <input type="text" className="form-control" placeholder="Client Name" value={clientName} id="clientName" ></input>
                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary">
                                Select Client
                            </Dropdown.Toggle>

                            <Dropdown.Menu variant="dark">
                                {clientList.map((item) => {
                                    if (item.is_active == true) {
                                        return (
                                            <Dropdown.Item key={nanoid()} onClick={() => { setClientName(item.clientName); setClientId(item.id) }}>{item.clientName}</Dropdown.Item>
                                        );
                                    }
                                })}
                            </Dropdown.Menu>
                        </Dropdown>
                        <div className="invalid-feedback">O nome do Projeto não pode ser vazio</div>
                    </div>
                    <div style={{ marginTop: '2%' }}>
                        <input className="form-check-input" type="checkbox" defaultChecked={isActive} id="flexCheckDefault02" style={{ width: "2%", height: "40px" }} onClick={x => { setIsActive(x.target.checked) }} />
                        <span style={{ marginLeft: "1%", fontSize: "30px", color: "white" }}>Ativo</span>
                    </div>
                    <a type="button" style={{ marginTop: "5%", fontSize: "20px" }} className="btn btn-secondary" id="submitButton" onClick={postdata}>Edit</a>
                </div>
            </div>
        </div>
    );
}