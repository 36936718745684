//importo meu objeto (authentication_api) para realizar as chamadas para API
import { authentication_api } from "../Api/Authentication"

//Crio um objeto para usar em todo o projeto
export var authentication_controller = {
    authenticate: Authenticate_Controller,
    authenticateRoutes: AuthenticateRoutes_Controller
}

//uso meu objeto para realizar a chamada para API
//Trato minha função Callback
function Authenticate_Controller() {
    var data = JSON.stringify("{}")
    authentication_api.authenticate(data, function (sucess) {
        if (sucess.data.isAdmin) {
            window.location = '/user-registration'
        } else if (!sucess.data.isAdmin) {
            window.location = '/register-hours';
        }
    });
}

//uso meu objeto para realizar a chamada para API
//Passo minha função callback para a tela
function AuthenticateRoutes_Controller(callback){
    var data = JSON.stringify("{}")
    authentication_api.authenticate(data, function (sucess) {
        callback(sucess);
    });
}