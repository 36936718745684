//importo meu objeto (spentHour_api) para realizar as chamadas para API
import _ from 'lodash';
import { spentHour_api } from '../Api/SpentHours'
import { helper } from '../Helper/helper';

//Crio um objeto para usar em todo o projeto
export var spentHours_controller = {
    create: CreateSpentHours_Controller,
    delete: DeleteSpentHours_Controller,
    edit: EditSpentHours_Controller,
    getUniqueSpentHour: GetUniqueSpentHours_Controller,
    listTotalHours: ListTotalSpentHours_Controller,
    list: ListSpentHours_Controller,
    update: UpdateControllSpentHours_Controller
}

//Recebo os parametros (projectId, date, spentHours, observation) da tela
//Uso meu objeto para realizar a chamada para API
//Crio um objeto (data) onde passo para a API
//Passo minha função callback para a tela
//Faço a verificações dos parametros
function CreateSpentHours_Controller(projectId, date, spentHours, observation, callback) {

    if (projectId && date && spentHours) {
        var data = JSON.stringify({
            "projectId": projectId,
            "date": date,
            "spentHours": spentHours,
            "observation": observation
        })

        spentHour_api.create(data, function (sucess) {
            callback(sucess);
        });
    } else {
        alert('Nome do projeto, data ou horas gastas não podem ser vazios');
    }
}

//Recebo os parametros (props) da tela
//Uso meu objeto para realizar a chamada para API
//Trato minha função callback
function DeleteSpentHours_Controller(props) {

    var data = JSON.stringify("{}");

    spentHour_api.delete(props, data, function (sucess) {
        alert("Registro de horas apagado com sucesso");
        props.ListEntryHours();
    }, function (error) {
        console.log(error.response.data.message);
    });
}

//Recebo os parametros (parameters) da tela
//Uso meu objeto para realizar a chamada para API
//Passo minha função callback para a tela
function GetUniqueSpentHours_Controller(parameters, callback) {

    var data = JSON.stringify("{}");

    spentHour_api.getUniqueSpentHour(parameters, data, function (sucess) {
        callback(sucess);
    })
}

//Recebo os parametros (id, projectId, date, spentHours) da tela
//Uso meu objeto para realizar a chamada para API
//Crio um objeto (data) onde passo para a API
//Trato minha função callback
//Faço a verificações dos parametros
function EditSpentHours_Controller(id, projectId, date, spentHours) {
    if (projectId && date && spentHours) {
        var data = JSON.stringify({
            "id": id,
            "projectId": projectId,
            "date": date,
            "spentHours": spentHours
        })

        spentHour_api.edit(data, function (sucess) {
            alert("Registro de horas editado com sucesso");
        });
    } else {
        alert('Nome do projeto, data ou horas gastas não podem ser vazios');
    }

}

//Uso meu objeto para realizar a chamada para API
//Passo minha função callback para a tela
function ListTotalSpentHours_Controller(callback) {
    var data = JSON.stringify("{}");
    spentHour_api.listTotalHours(data, function (sucess) {
        var spentObject = [];
        var groupedClients = _.groupBy(sucess.data, (item) => { return item.clientName });
        const result = _.map(groupedClients, (value, key) => {
            var hours = _.sumBy(groupedClients[key], (v) => +v.totalHours.replace(':00', '').replace(':', '.'));
            var name = `${key}`;
            spentObject.push({ "value": hours, "name": name });
        });
        callback(spentObject);
    })
}

//Recebo os parametros (parameters) da tela
//Uso meu objeto para realizar a chamada para API
//Passo minha função callback para a tela
function ListSpentHours_Controller(parameter, callback) {
    var data = JSON.stringify("{}");
    spentHour_api.list(parameter, data, function (sucess) {
        callback(sucess);
    });
}

//Recebo os parametros (spentHourId, projectId, date, spentHours, observation, props) da tela
//Uso meu objeto para realizar a chamada para API
//Crio um objeto (data) onde passo para a API
//Trato minha função callback
//Faço a verificações dos parametros
function UpdateControllSpentHours_Controller(spentHourId, projectId, date, spentHours, observation, props) {
    if (projectId && date && spentHours) {

        var data = JSON.stringify({
            "id": spentHourId,
            "projectId": projectId,
            "date": date,
            "spentHours": spentHours,
            "observation": observation
        });

        spentHour_api.update(data, function () {
            alert("Editado com sucesso");
            props.setTrigger(false);
            props.setDate(date);
            props.func(helper.formatData(date));
        });

    } else {
        alert('Nome do projeto, data ou horas gastas não podem ser vazios');
    }
}