import _ from "lodash";
import { nanoid } from 'nanoid';
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useParams } from "react-router-dom";

import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import { client_controller } from "../Controller/Cliente";
import { project_controller } from "../Controller/Project";
import { spentHours_controller } from "../Controller/SpentHours";
import { helper } from "../Helper/helper";

export default function EditControllSpentHours() {

    //#region States


    const [clientName, setClientName] = useState('');
    const [clientList, setClientList] = useState([]);

    const [projectId, setProjectId] = useState(0);

    const [projectName, setProjectName] = useState('')
    const [projectList, setProjectList] = useState([]);

    const [spentHours, setSpentHours] = useState('');

    const [date, setDate] = useState('');
    const [hours, setHours] = useState(null);

    var params = useParams();
    var id = +params.id.replace(':', '');

    var splitedDate = new Date().toString().split(' ');

    //#endregion

    //#region API Call

    function ListClient() {
        client_controller.list(function (sucess) {
            setClientList(sucess.filter(x => !x.HasProject))
        })
    }

    useEffect(() => { ListClient() }, []);

    function ListProject(selectedClientName) {
        project_controller.list(selectedClientName, clientList, function (sucess) {
            setProjectList(sucess);
        });
    }

    function getApiData() {
        spentHours_controller.getUniqueSpentHour(id, function (sucess) {
            setClientName(sucess.data.clientName);
            setProjectName(sucess.data.projectName);
            setSpentHours(sucess.data.spentHours);
            setHours(`${splitedDate[0]} ${splitedDate[1]} ${splitedDate[2]} ${splitedDate[3]} ${sucess.data.spentHours} ${splitedDate[5]} ${splitedDate[6]} ${splitedDate[7]} ${splitedDate[8]}`);
            setDate(sucess.data.date.replace('T00:00:00', ''));
            setProjectId(sucess.data.projectId);
        })
    }

    useEffect(() => {
        if (clientName !== '') {
            ListProject(clientName);
        }
    }, [clientName])

    useEffect(() => { getApiData(); }, []);

    function postdata() {
        spentHours_controller.edit(id, projectId, date, spentHours)
    }

    //#endregion

    return (
        <div>
            <div className="col-md-12">
                <div style={{ paddingLeft: "5%", paddingTop: "3%", paddingBottom: "3%" }}>
                    <a type="button" className="btn btn-secondary" href="/entry-control-spent-hours">Voltar</a>
                </div>
                <div style={{ paddingLeft: "5%" }}>
                    <div>
                        <h2 style={{ color: "white" }}>Editar Entrada de Hora</h2>
                    </div>
                    <br />
                    <h5 style={{ color: "white" }}>Nome do cliente</h5>
                    <div className="input-group has-validation mb-3" style={{ width: "50%" }}>
                        <input type="text" className="form-control" placeholder="Nome do cliente" defaultValue={clientName} id="ClientName" readOnly></input>
                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary">
                                Selecionar Cliente
                            </Dropdown.Toggle>

                            <Dropdown.Menu variant="dark">
                                {clientList.map((item) => {
                                    return (
                                        <Dropdown.Item key={nanoid()} onClick={() => { setClientName(item.clientName); ListProject(item.clientName); setProjectName(''); }}>{item.clientName}</Dropdown.Item>
                                    );
                                })}
                            </Dropdown.Menu>
                        </Dropdown>
                        <div className="invalid-feedback">O nome do Projeto não pode ser vazio</div>
                    </div>
                    <br />
                    <h5 style={{ color: "white" }}>Nome do projeto</h5>
                    <div className="input-group has-validation mb-3" style={{ width: "50%" }}>
                        <input type="text" className="form-control" placeholder="Nome do projeto" defaultValue={projectName} id="ProjectName" readOnly></input>
                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary">
                                Selecionar projeto
                            </Dropdown.Toggle>

                            <Dropdown.Menu variant="dark">
                                {projectList.map((item) => {
                                    return (
                                        <Dropdown.Item key={nanoid()} onClick={() => { setProjectName(item.projectName); setProjectId(item.projectId) }}>{item.projectName}</Dropdown.Item>
                                    );
                                })}
                            </Dropdown.Menu>
                        </Dropdown>
                        <div className="invalid-feedback">O nome do Projeto não pode ser vazio</div>
                    </div>
                    <br />
                    <h5 style={{ color: "white" }}>Horas gastas</h5>
                    <div className="input-group has-validation mb-3" style={{ width: "50%" }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <TimePicker
                                ampm={false}
                                openTo="hours"
                                views={['hours', 'minutes']}
                                inputFormat="HH:mm"
                                mask="__:__"
                                value={hours}
                                onChange={(newValue) => {
                                    if (helper.formatHours(newValue) > '08:00') {
                                        alert('A hora selecionada é maior que 8 horas ');
                                    }
                                    setHours(newValue);
                                    setSpentHours(helper.formatHours(newValue));
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                        {/* <input type="time" className="form-control" defaultValue={spentHours} onChange={(x) => { setSpentHours(x.target.value); }}></input> */}
                    </div>
                    <br />
                    <h5 style={{ color: "white" }}>Data</h5>
                    <div className="input-group has-validation mb-3" style={{ width: "50%" }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                value={date}
                                inputFormat="dd/MM/yyyy"
                                onChange={(newValue) => {
                                    setDate(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                        {/* <input type="date" className="form-control" placeholder="Date" defaultValue={date} onChange={(x) => { setDate(x.target.value); }}></input> */}
                    </div>
                    <br />
                    <a type="button" style={{ marginTop: "3%", fontSize: "20px" }} className="btn btn-secondary" id="submitButton" onClick={postdata}>Editar</a>
                </div>
            </div>
        </div>
    );
}