import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { client_controller } from "../Controller/Cliente";

export default function EditClient() {

    //#region States

    const [clientName, setclientName] = useState("");
    const [isActive, setIsActive] = useState(false);

    var id = useParams();
    id = +id.id.replace(':', '');

    //#endregion

    //#region API Call

    function getApiData() {
        client_controller.getUniqueClient(id, function (sucess) {
            setIsActive(sucess.data.is_active);
            setclientName(sucess.data.name);
        })
    }

    useEffect(() => { getApiData(); }, []);

    function postdata() {
        client_controller.edit(id, clientName, isActive);
    }

    //#endregion

    return (
        <div>
            <div className="col-md-12">
                <div style={{ padding: "5%" }}>
                    <a type="button" className="btn btn-secondary" id="submitButton" href={`/client-registration:${id}`}>Voltar</a>
                </div>
                <div style={{ paddingLeft: "5%" }}>
                    <div>
                        <h2 style={{ color: "white" }}>Edit Client</h2>
                    </div>
                    <br />
                    <div className="input-group has-validation" style={{ width: "50%" }}>
                        <input type="text" className="form-control" placeholder="User Name" id="clientName" value={clientName} onChange={(x) => { setclientName(x.target.value); }}></input>
                        <div className="invalid-feedback">O nome do cliente não pode ser vazio</div>
                    </div>
                    <br />
                    <div>
                        <input className="form-check-input" type="checkbox" id="flexCheckDefault02" checked={isActive} style={{ width: "2%", height: "40px" }} onClick={x => { setIsActive(x.target.checked) }} />
                        <span style={{ marginLeft: "1%", fontSize: "x-large", color: "white" }}>Is Active</span>
                    </div>
                    <a type="button" style={{ marginTop: "5%", fontSize: "20px" }} className="btn btn-secondary" id="submitButton" onClick={postdata}>Edit</a>
                </div>
            </div>
        </div>
    );
}
