import { validate } from 'email-validator';
import React, { useState } from "react";

import { usuario_Controller } from '../Controller/Usuario';

function CreateUser() {

    //#region States

    const [userLogin, setLogin] = useState([]);
    const [userName, setUserName] = useState("");
    const [userPassword, setUserPassword] = useState("");
    const [isAdmin, setIsAdmin] = useState(false);
    const [isActive, setIsActive] = useState(true);

    //#endregion

    //#region API Call

    function postdata() {
        usuario_Controller.create(userName, userPassword, userLogin, isAdmin, isActive);
    }

    //#endregion

    return (
        <div>
            <div className="col-md-12">
                <div style={{ padding: "5%" }}>
                    <a type="button" className="btn btn-secondary" href="/user-registration">Voltar</a>
                </div>
                <div style={{ paddingLeft: "5%" }}>
                    <div>
                        <h2 style={{ color: "white" }}>Registrar Usuário</h2>
                    </div>
                    <br />
                    <div className="input-group has-validation" style={{ width: "50%" }}>
                        <input type="text" className="form-control" placeholder="Nome do usuário" id="UserName" onChange={(x) => { setUserName(x.target.value); }}></input>
                        <div className="invalid-feedback">O nome de usuário não pode ser vazio e deve conter pelo menos 3 caracteres</div>
                    </div>
                    <br />
                    <div className="input-group has-validation" style={{ width: "50%" }}>
                        {/* <span className="input-group-text">@</span> */}
                        <input type="text" className="form-control" placeholder="Login do usuário" id="Login" onChange={(x) => { setLogin(x.target.value); }}></input>
                        <div className="invalid-feedback" id="emailResponse">insira um email válido</div>
                    </div>
                    <br />
                    <div className="input-group has-validation" style={{ width: "50%" }}>
                        <input type="password" className="form-control" placeholder="Senha do usuário" id="Password" onChange={(x) => { setUserPassword(x.target.value); }} required></input>
                        <div className="invalid-feedback">A senha deve conter mais de 6 caracteres</div>
                    </div>
                    <br />
                    <div>
                        <input className="form-check-input" type="checkbox" id="flexCheckDefault02" style={{ width: "2%", height: "40px" }} onClick={x => { setIsAdmin(x.target.checked); }} />
                        <span style={{ marginLeft: "1%", fontSize: "x-large", color: "white" }}>Administrador</span>
                    </div>
                    <br />
                    <div>
                        <input className="form-check-input" checked={isActive} type="checkbox" id="flexCheckDefault02" style={{ width: "2%", height: "40px" }} onClick={x => { setIsActive(x.target.checked); }} />
                        <span style={{ marginLeft: "1%", fontSize: "x-large", color: "white" }}>Ativo</span>
                    </div>
                    <a type="button" style={{ marginTop: "5%", fontSize: "20px" }} className="btn btn-secondary" id="submitButton" onClick={postdata}>Registrar</a>
                </div>
            </div>
        </div>
    );
}


export default CreateUser;