import { validate } from 'email-validator';
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { usuario_Controller } from '../Controller/Usuario';

export default function EditUser() {

    //#region States
    const [userLogin, setLogin] = useState([]);
    const [userName, setUserName] = useState("");
    const [userPassword, setUserPassword] = useState("");
    const [isAdmin, setIsAdmin] = useState(false);
    const [isActive, setIsActive] = useState(null);

    var id = useParams();
    id = +id.id.replace(':', '');

    //#endregion

    //#region API Call

    function getApiData() {
        usuario_Controller.getUniqueUser(id, function (sucess) {
            setLogin(sucess.data.login);
            setUserName(sucess.data.name);
            setIsAdmin(sucess.data.is_admin);
            setUserPassword(sucess.data.password);
            setIsActive(sucess.data.is_active);
        })
    }

    useEffect(() => { getApiData(); }, []);

    function postdata() {
        usuario_Controller.update(id, userName, userLogin, userPassword, isAdmin, isActive);
    }

    //#endregion

    return (
        <div>
            <div className="col-md-12">
                <div style={{ padding: "5%" }}>
                    <a type="button" className="btn btn-secondary" href="/user-registration">Voltar</a>
                </div>
                <div style={{ paddingLeft: "5%" }}>
                    <div>
                        <h2 style={{ color: "white" }}>Edit User</h2>
                    </div>
                    <br />
                    <div className="input-group has-validation" style={{ width: "50%" }}>
                        <input type="text" className="form-control" placeholder="User Name" id="UserName" value={userName} onChange={(x) => { setUserName(x.target.value); }}></input>
                        <div className="invalid-feedback">O nome de usuário não pode ser vazio e deve conter pelo menos 3 caracteres</div>
                    </div>
                    <br />
                    <div className="input-group has-validation" style={{ width: "50%" }}>
                        <input type="text" className="form-control" placeholder="Login" id="Login" value={userLogin} onChange={(x) => { setLogin(x.target.value); }}></input>
                        <div className="invalid-feedback" id="emailResponse">insira um email válido</div>
                    </div>
                    <br />
                    <div>
                        <input className="form-check-input" type="checkbox" id="isAdmin" style={{ width: "2%", height: "40px" }} checked={isAdmin} onClick={x => { setIsAdmin(x.target.checked) }} />
                        <span style={{ marginLeft: "1%", fontSize: "x-large", color: "white" }}>Administrador</span>
                    </div>
                    <br />
                    <div>
                        <input className="form-check-input" type="checkbox" defaultChecked={isActive} id="flexCheckDefault02" style={{ width: "2%", height: "40px" }} onClick={x => { setIsActive(x.target.checked); }} />
                        <span style={{ marginLeft: "1%", fontSize: "x-large", color: "white" }}>Ativo</span>
                    </div>
                    <a type="button" style={{ marginTop: "5%", fontSize: "20px" }} className="btn btn-secondary" id="submitButton" onClick={postdata}>Edit</a>
                </div>
            </div>
        </div>
    );
}
